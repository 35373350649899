import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

export const getProductList = async (params: any) => {
  try {
    const url = `${BASE_URL_BETA}/admin/price-history`;
    const response = await axios.get(url, { params: params });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
  }
};

export const getPriceHistoryDetail = async (product_id: number, params: any) => {
  try {
    const url = `${BASE_URL_BETA}/admin/price-history/${product_id}`;
    const response = await axios.get(url, {
      params: {...params, product_id},
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
  }
};
