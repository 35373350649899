import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const getPharmacyList = async () => {
  try {
    let queryParams = new URLSearchParams({
      // search: '',
      sorted_by: "company_name",
      order_by: "asc",
    } as any).toString();

    const url = `${BASE_URL_BETA}/admin/company?${queryParams}`;
    const response = await axios.get(url);

    if (response && response.status === 200) {
      return response.data.company;
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
  }
};

export default getPharmacyList;
