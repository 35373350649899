import { notification } from "antd";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useGetProductTaxHistoryList } from "../../../../service/product/useProductService";

export const useTaxHistoryReportDetail = () => {
   const { productId } = useParams() as any;
   const history = useHistory();

   const onError = () => {
      notification.error({
         message: "Gagal memuat",
         placement: "topRight",
         duration: 2,
      });
   };

   const [filter, setFilter] = useState({ p: 1, rp: 50 });
   const { list, productName, totalData, isFetching } = useGetProductTaxHistoryList(
      !!productId,
      productId,
      filter,
      onError
   );

   const onBack = () => {
      history.goBack();
   };

   const onChangePage = (p: number) => {
      setFilter({ ...filter, rp: 50, p });
   };

   return {
      list,
      productName,
      totalData,
      filter,
      isFetching,
      onBack,
      onChangePage,
   };
};
