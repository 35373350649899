import { Alert, Button, Col, DatePicker, Form, Input, Layout, Row, Select, Tag, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { useEffect, useRef, useState } from "react";

import Sidebar from "../../component/Sidebar";
import ModalConfirmationPromo from "./ModalConfirm";
import { getPromo, UpdatePayloadPromoType, updaterPromo } from "./utils/servicePromotion";

const PromotionDefaultPage = () => {
  const [focused, setFocused] = useState(false);
  const [form, setForm] = useState<UpdatePayloadPromoType>({
    button_text: "",
    subtitle: "",
    title: "",
    start_date: "",
    end_date: "",
    type: "",
    url_target: "",
    domain_target: "",
  });
  const re = new RegExp("{APP_NAME}", "g");

  const [loading, setLoading] = useState(false);
  const refInput = useRef<any>();
  const handleSetForm = (e: any) => {
    const key = e.target.name;
    const value = e.target.value;
    if (key === "date") {
      setForm({
        ...form,
        start_date: value?.[0]?.format("YYYY-MM-DD") || "",
        end_date: value?.[1]?.format("YYYY-MM-DD") || "",
      });
    } else {
      setForm({ ...form, [key]: value });
    }
  };
  const currentDomainArray = form.domain_target.split(" ");
  const onChangeDomainTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((last) => {
      let currentText = last.domain_target.split(" ");
      currentText[currentText.length - 1] = e.target.value;
      return { ...last, domain_target: currentText.join(" ") };
    });
  };

  const onDeleteByIndex = (e: number) => {
    setForm((last) => {
      let currentTextArray = currentDomainArray.filter((_, idx) => idx !== e);
      return { ...last, domain_target: currentTextArray.join(" ") };
    });
  };

  const fetch = async () => {
    setLoading(true);
    const data = await getPromo();
    if (data?.promo) {
      setForm({ ...data?.promo, domain_target: `${data?.promo?.domain_target?.replace(/,/g, " ")} ` });
    }
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    await updaterPromo({ ...form, domain_target: form.domain_target.trim().replace(/ /g, ",") });
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Layout style={{ height: "100vh" }} {...formItemLayout}>
      <Sider breakpoint='lg' collapsedWidth='0'>
        <Sidebar activeTab={"promotion"} />
      </Sider>
      <Layout>
        <Header style={{ background: "#fff" }}>
          <h1>Promotion</h1>
        </Header>
        <Content
          style={{
            padding: "25px",
            paddingBottom: "100px",
            overflowY: "scroll",
          }}
        >
          <Content
            style={{
              background: "white",
              padding: "35px 25px 25px 25px",
              boxShadow: "0.1px 0.1px 5px 1px #dedddd",
              borderRadius: "5px",
            }}
          >
            <Alert
              style={{ marginBottom: "1rem", background: "white" }}
              message={
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `Use <b>{APP_NAME}</b> to replace the "Application Name" at input <b>Title and Content</b> `,
                    }}
                    style={{ margin: "0" }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `Eg:  <i>Berlangganan {APP_NAME}</i> <b>menjadi</b> <i>Berlangganan Farmacare</i>`,
                    }}
                    style={{ margin: "0" }}
                  />
                  <hr />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `Use <b>{WORKSPACE_ID}</b> without domain to replace the "URL ID" at input <b>Link button</b> `,
                    }}
                    style={{ margin: "0" }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `Eg:  <i style="background:white; padding:2px">/{WORKSPACE_ID}/order/distributor/4821</i> <b>menjadi</b> <i style="background:white; padding:2px">https://app.farmacare.id/595/order/distributor/4821</i>`,
                    }}
                    style={{ margin: "0" }}
                  />
                </div>
              }
              type='info'
              showIcon
            />
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              onFinish={(e) => {
                e?.preventDefault();
              }}
              layout='horizontal'
            >
              {/* <Form.Item label='Note'>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `Use <b>{APP_NAME}</b> to replace the "Application Name" at input <b>Title and Content</b> `,
                  }}
                  style={{ margin: "0" }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: `Eg:  <i>Berlangganan {APP_NAME}</i> <b>menjadi</b> <i>Berlangganan Farmacare</i>`,
                  }}
                  style={{ margin: "0" }}
                />
              </Form.Item> */}
              <Form.Item label='Type'>
                <Select
                  value={form.type}
                  style={{ width: 200 }}
                  onChange={(e) => handleSetForm({ target: { name: "type", value: e } })}
                  options={[
                    { value: "promotion", label: "Promotion" },
                    { value: "announcement", label: "Announcement" },
                  ]}
                />
              </Form.Item>
              <Form.Item label='Title'>
                <Input
                  placeholder=''
                  onChange={handleSetForm}
                  value={form.title}
                  name='title'
                  size='large'
                  style={{ fontWeight: "bold" }}
                />
              </Form.Item>
              <Form.Item label='Content'>
                <TextArea rows={4} size='large' onChange={handleSetForm} name='subtitle' value={form.subtitle} />
              </Form.Item>
              <Form.Item label='Buton text'>
                <Input placeholder='' onChange={handleSetForm} name='button_text' size='large' value={form.button_text} />
              </Form.Item>
              {form.type === "announcement" && (
                <Form.Item label='Link button'>
                  <Input placeholder='' onChange={handleSetForm} value={form.url_target} name='url_target' size='large' />
                </Form.Item>
              )}

              <Form.Item label='Domain target'>
                <div
                  style={{
                    border: `solid 1px #d6d6d6`,
                    borderColor: "#fffffff",
                    padding: "4px",
                    borderRadius: "3px",
                    width: "100%",
                    outline: focused ? "solid 1px #25c1ff" : undefined,
                  }}
                  onClick={() => refInput.current?.focus()}
                >
                  {currentDomainArray.map((e, idx) => {
                    if (currentDomainArray.length - 1 === idx) {
                      return (
                        <input
                          ref={refInput}
                          style={{ outline: "none", border: "none", fontSize: "16px" }}
                          placeholder=''
                          onFocus={() => {
                            setFocused(true);
                          }}
                          onBlur={() => {
                            setFocused(false);
                            if (!!e) {
                              setForm((last) => {
                                return { ...last, domain_target: `${last.domain_target} ` };
                              });
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.code === "Space") {
                              setForm((last) => {
                                return { ...last, domain_target: `${last.domain_target} ` };
                              });
                              setTimeout(() => {
                                refInput.current.focus();
                              }, 400);
                              return;
                            }
                            if (e.code === "Backspace" && !e.currentTarget.value) {
                              setForm((last) => {
                                return { ...last, domain_target: last.domain_target.substring(0, last.domain_target.length - 1) };
                              });
                              setTimeout(() => {
                                refInput.current.focus();
                              }, 400);
                            }
                          }}
                          onChange={onChangeDomainTarget}
                          value={e}
                          name='domain_target'
                        />
                      );
                    }
                    if (!!e) {
                      return (
                        <Tag closable onClose={() => onDeleteByIndex(idx)} key={idx}>
                          {e}
                        </Tag>
                      );
                    }
                    return null;
                  })}
                </div>
                <p style={{ fontSize: "12px", marginTop: "2px" }}>NB: If left blank it will appear on all domains</p>
              </Form.Item>
              {form.type === "announcement" && (
                <Form.Item label='Link button'>
                  <Input placeholder='' onChange={handleSetForm} value={form.url_target} name='url_target' size='large' />
                </Form.Item>
              )}
              <Form.Item label='Date'>
                <DatePicker.RangePicker
                  size='large'
                  value={[
                    moment(form.start_date).isValid() ? moment(form.start_date) : null,
                    moment(form.end_date).isValid() ? moment(form.end_date) : null,
                  ]}
                  format='YYYY-MM-DD'
                  onChange={(e) => handleSetForm({ target: { value: e, name: "date" } })}
                />
              </Form.Item>

              <Form.Item label='Notes'>
                <Alert
                  message='For hide it form client board, just delete title and save it. For show in client board, just fill this form.'
                  type='info'
                  showIcon
                />
              </Form.Item>
              <Row>
                <Col span={11} />
                <Col span={11} style={{ display: "flex", justifyContent: "end" }}>
                  <ModalConfirmationPromo onConfirm={handleSave} isLoading={loading} />
                </Col>
              </Row>
            </Form>
          </Content>
          {form.button_text || form.subtitle || form.title ? (
            <Content
              style={{
                marginTop: "10px",
                background: "white",
                padding: "10px",
              }}
            >
              <Text style={{ fontWeight: "bold" }}>Review</Text>
              <Content
                style={{
                  background: "#fff8e6",
                  marginTop: "10px",
                  padding: "15px",
                  boxShadow: "0.1px 0.1px 5px 1px #dedddd",
                  borderRadius: "2px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    {/* <NotificationOutlined /> */}
                    <IconNotice type={form.type} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "calc(100% - 80px)",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {form?.title?.replace(re, "Farmacare")}
                      </Typography>
                      <Typography style={{ fontSize: "14px" }}>{form?.subtitle?.replace(re, "Farmacare")}</Typography>
                    </div>
                  </div>
                  {(form.type === "promotion" || (form.button_text && form.type === "announcement" && form.url_target)) && (
                    <Button
                      type='primary'
                      style={{
                        background: "rgb(15, 76, 82)",
                        borderRadius: "5px",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {form.button_text}
                    </Button>
                  )}
                </div>
              </Content>
            </Content>
          ) : null}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PromotionDefaultPage;

const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 100,
  },
};

const IconNotice = ({ type }: { type: "promotion" | "announcement" | "" }) => {
  if (type === "announcement") {
    return (
      <div style={{ width: "17px", height: "17px", margin: "5px 10px 0 0", transform: "scaleX(-1)" }}>
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M3.56204 2.45834C3.32917 2.39042 3.08369 2.37765 2.84503 2.42105C2.60638 2.46444 2.3811 2.5628 2.18704 2.70834C1.99299 2.85389 1.83548 3.04261 1.727 3.25957C1.61852 3.47653 1.56204 3.71577 1.56204 3.95834V14.7917C1.56204 15.0342 1.61852 15.2735 1.727 15.4904C1.83548 15.7074 1.99299 15.8961 2.18704 16.0417C2.3811 16.1872 2.60638 16.2856 2.84503 16.329C3.08369 16.3724 3.32917 16.3596 3.56204 16.2917L9.6875 14.5051V15C9.6875 15.4144 9.85212 15.8118 10.1451 16.1049C10.4382 16.3979 10.8356 16.5625 11.25 16.5625H13.75C14.1644 16.5625 14.5618 16.3979 14.8549 16.1049C15.1479 15.8118 15.3125 15.4144 15.3125 15V12.8645L17.312 12.2813C17.6366 12.1866 17.9217 11.9892 18.1245 11.7188C18.3274 11.4483 18.437 11.1193 18.437 10.7813V7.96876C18.437 7.63068 18.3274 7.30172 18.1245 7.03126C17.9217 6.7608 17.6366 6.56342 17.312 6.46876L10.9088 4.60116C10.895 4.59677 10.8811 4.5927 10.867 4.58894L3.56204 2.45834ZM9.6875 6.19806V12.552L3.43704 14.375V4.37501L9.6875 6.19806ZM11.5625 13.9582L13.4375 13.4113V14.6875H11.5625V13.9582ZM14.0914 11.2675L11.5625 12.0051V6.74494L16.562 8.20313V10.5469L14.1328 11.2554C14.1189 11.2591 14.1051 11.2632 14.0914 11.2675Z'
            fill='rgb(161, 125, 18)'
          />
        </svg>
      </div>
    );
  }
  return (
    <div style={{ width: "17px", height: "17px", margin: "5px 10px 0 0" }}>
      <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 464 464' xmlnsXlink='http://www.w3.org/1999/xlink'>
        <path
          fill='rgb(161, 125, 18)'
          d='m394.33,105.596h-76.136c22.944-22.372 37.216-53.596 37.216-88.096 0-9.665-7.835-17.5-17.5-17.5-44.786,0-84.057,24.045-105.596,59.9-21.539-35.855-60.81-59.9-105.595-59.9-9.665,0-17.5,7.835-17.5,17.5 0,34.5 14.273,65.724 37.216,88.096h-76.137c-9.665,0-17.5,7.835-17.5,17.5v324.033c0,9.665 7.835,17.5 17.5,17.5h324.032c9.665,0 17.5-7.835 17.5-17.5v-324.033c0-9.665-7.835-17.5-17.5-17.5zm-17.5,162.016h-127.016v-127.016h127.016v127.016zm-58.646-230.385c-7.525,32.765-33.378,58.618-66.144,66.143 7.526-32.766 33.379-58.619 66.144-66.143zm-105.596,66.142c-32.765-7.525-58.618-33.378-66.143-66.143 32.765,7.525 58.618,33.378 66.143,66.143zm2.226,37.227v127.016h-127.016v-127.016h127.016zm-127.016,162.016h127.016v127.017h-127.016v-127.017zm162.016,127.016v-127.016h127.016v127.017h-127.016z'
        />
      </svg>
    </div>
  );
};
