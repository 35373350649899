import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Form, Input, Button, Row, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Layout from "antd/lib/layout/layout";

// utils
import postLogin from "./utils/postLogin";

const Demo = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = async (values: any) => {
    try {
      await postLogin(username, password);
      history.push("/company/pharmacy");
      notification.success({ message: "Berhasil login", duration: 2 });
    } catch (err) { }
  };

  return (
    <Layout
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Row>
        <h1>Farmacare</h1>
      </Row>
      <Form
        form={form}
        name="horizontal_login"
        layout="inline"
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Log in
            </Button>
          )}
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default Demo;
