import { notification } from "antd";

import axios from '../../../config/axios'

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const putUserRole = async (id: any, role: string = 'client') => {
  try {
    const url = `${BASE_URL_BETA}/admin/user/${id}`;

    const payload = {
        role: role
    }

    const response = await axios.put(url, payload);

    if (response && response.status === 200) {
        return response.data
    }
  } catch (err) {
    notification.error({
      message: "Gagal memperbaharui",
      placement: "topRight",
      duration: 2,
    });
    throw new Error(err);
  }
};

export default putUserRole
