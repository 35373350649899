import axios from "axios";

// constant
import { BASE_URL_BETA } from "../constant/api";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === 422) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");

      return axios
        .post(`${BASE_URL_BETA}/refresh-token`, {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem(
              "token",
              res.data.AuthenticationResult.IdToken
            );
            localStorage.setItem(
              "accessToken",
              res.data.AuthenticationResult.AccessToken
            );
            localStorage.setItem(
              "refreshToken",
              res.data.AuthenticationResult.RefreshToken
            );

            axios.defaults.headers.common["Authorization"] =
              localStorage.getItem("token");

            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);

export default axios;
