import axios from "axios";
import { BASE_URL_BETA } from "../../constant/api";

export class ProductService {
   static getProductHistoryList(params: any) {
      return axios.get(`${BASE_URL_BETA}/admin/price-history`, { params });
   }

   static getTaxReportHistoryProduct(productId: any, params: any) {
      return axios.get(`${BASE_URL_BETA}/admin/tax-history/${productId}`, { params });
   }
}
