import { notification } from "antd";

import axios from '../../../config/axios'

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const getDashboard = async (dashboard_name: string) => {
  try {
    let queryParams = new URLSearchParams({
      dashboard: dashboard_name
    } as any).toString();
    
    const url = `${BASE_URL_BETA}/company/1/metabase/dashboard?${queryParams}`;
    const response = await axios.get(url);

    if (response && response.status === 200) {
        return response.data.url
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });

  }
};

export default getDashboard
