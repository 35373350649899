import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

export const getPromo = async () => {
  try {
    const url = `${BASE_URL_BETA}/promo`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    notification.error({ message: "Gagal memperoleh data promo" });
  }
};

export type UpdatePayloadPromoType = {
  title: string;
  subtitle: string;
  button_text: string;
  start_date: string;
  end_date: string;
  type: "promotion" | "announcement" | "";
  url_target: string;
  domain_target: string;
};

export const updaterPromo = async (data: UpdatePayloadPromoType) => {
  try {
    const url = `${BASE_URL_BETA}/promo`;
    await axios.put(url, data);
    notification.success({ message: "Berhasil memperbarui data." });
    return "success";
  } catch (err) {
    notification.error({ message: "Gagal memperbarui data." });
    return "error";
  }
};
