export interface MonitorSystemResources {
  id: number;
  resource_type: string;
  resource_name: string;
  resource_status: string;
  created_at: string;
  updated_at: string;
}

export interface MonitorSystemResourcesLog {
  id: number;
  alarm_type: string;
  alarm_name: string;
  alarm_state: string;
  alarm_descriptions: string;
  alarm_time: string;
  created_at: string;
}

export const defaultMonitorSystemResources: MonitorSystemResources = {
  id: 0,
  resource_type: "",
  resource_name: "",
  resource_status: "",
  created_at: "",
  updated_at: ""
};


export const defaultMonitorSystemResourceLogs: MonitorSystemResourcesLog = {
  id: 0,
  alarm_type: "",
  alarm_name: "",
  alarm_state: "",
  alarm_descriptions: "",
  alarm_time: "",
  created_at: ""
};
