
export interface Logger {
  id: number, 
  log_level: 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR' | 'CRITICAL', 
  host: string, 
  endpoint: string, 
  method: 'GET' | 'POST' | 'PUT' | 'FETCH' | 'DELETE', 
  company_id: number, 
  company_name: string, 
  request_header: string, 
  request_query_string: string, 
  request_body: string, 
  payload: string, 
  response_header: string, 
  response_body: string, 
  response_code: number, 
  message: string, 
  duration: number, 
  created_at: string, 
  created_by: number,
  username: string
}

export const defaultLog:Logger = {
  id: 0,
  created_at: '',
  log_level: 'INFO',
  host: '',
  endpoint: '',
  method: 'GET',
  company_id: 0,
  company_name: '',
  request_header: '{}',
  request_query_string: '{}',
  request_body: '{}',
  payload: '{}',
  response_header: '{}',
  response_body: '{}',
  response_code: 200,
  message: '',
  duration: 0,
  created_by: 0,
  username: ''
}