import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
   Layout,
   Table,
   Row,
   Col,
   Modal,
   Input,
   notification,
   Select,
   Radio,
   Button,
   Alert,
   Spin,
   Menu,
} from "antd";
import moment, { Moment } from "moment";

// component
import Sidebar from "../../component/Sidebar";

// utils
import putActiveDate from "./utils/putActiveDate";

// type
import { CompanyType, Pharmacy } from "../../types/pharmacy";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import PharmacyLogComponent from "./PharmacyLog";
import { isExternalAcces, isInternalAccess } from "../../utils/roles";
import { useParams } from "react-router-dom";
import { getRegionList } from "./utils/getRegionList";
import { addOrCreateChainGroups, getDetailChain } from "./utils/chainGroups";
import { LoadingOutlined } from "@ant-design/icons";
import PharmacyForm from "./PharmacyForm";
import { useGetPharmacyList } from "src/service/pharmacy/usePharmacyService";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;

const PharmacyComponent = (props: any) => {
   const { Header, Content, Sider } = Layout;
   const { Search } = Input;
   const params = useParams<{ type: string }>();
   const companyTypeParam = props.match.params.type;

   const [filter, setFilter] = useState({ current: 1, pageSize: 50 });
   const [title, setTitle] = useState("");
   const [selectedPharmacy, setSelectedPharmacy] = useState<number | null>(null);
   const [modalVisible, setModalVisible] = useState(false);
   const [modalLogVisible, setModalLogVisible] = useState(false);
   const [showModalReason, setShowModalReason] = useState(false);
   const userRole = String(localStorage.getItem("userRole"));
   const [tempDate, setTempDate] = useState<null | Moment>(null);
   const [tempStatus, setTempStatus] = useState("");
   const [date, setDate] = useState<null | Moment>(null);
   const [status, setStatus] = useState("");
   const [isPaid, setIsPaid] = useState(0);
   const [isInternal, setIsInternal] = useState(0);
   const [name, setName] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [search, setSearch] = useState("");
   const [hideWorkspace, setHideWorkspace] = useState(false);
   const [reason, setReason] = useState("");
   const [activeTab, setActiveTab] = useState("active");

   const { list, refetch, isFetching, totalData } = useGetPharmacyList(
      { ...filter, type: companyTypeParam, search: search, is_hidden: activeTab === "hidden" },
      true,
      () => {
         notification.error({ message: "Gagal memuat", duration: 5000 });
      }
   );

   const filteredList = useMemo(() => {
      return list.filter((f) => {
         return activeTab === "active" ? !f.is_hidden : f.is_hidden;
      });
   }, [list, activeTab]);

   const handleSelectPharmacy = (data: any) => {
      let active_until = data.activeUntil.activeUntil ? moment(data.activeUntil.activeUntil) : null;

      setModalVisible(true);
      setSelectedPharmacy(data.id);
      setName(data.name);
      setDate(active_until);
      setTempDate(active_until);
      setTempStatus(data.status);
      setIsInternal(data.is_internal);
      setStatus(data.status);
      setIsPaid(data.is_paid);
      setHideWorkspace(!!data.is_hidden);
   };

   const handleLogPharmacy = (data: any) => {
      setModalLogVisible(true);
      setSelectedPharmacy(data.id);
   };

   const handleCloseModal = () => {
      setSelectedPharmacy(null);
      setDate(null);
      setModalVisible(false);
      setModalLogVisible(false);
      refetch();
   };

   const handleChangeDate = (date: any) => {
      setDate(date);
   };

   const onUpdate = async () => {
      let formattedDate = "";

      if (isInternalAccess(userRole)) {
         formattedDate = (date as Moment).format("YYYY-MM-DD HH:mm:ss");
      } else {
         formattedDate = moment().add(14, "days").format("YYYY-MM-DD HH:mm:ss");
      }

      try {
         setIsLoading(true);

         await putActiveDate(
            selectedPharmacy!,
            formattedDate,
            status,
            isPaid,
            isInternal,
            hideWorkspace,
            reason,
            title
         );

         setTitle("");
         setReason("");

         setIsLoading(false);
         setShowModalReason(false);
         handleCloseModal();
         refetch();

         notification.success({
            message: "Berhasil memperbaharui tanggal aktif",
            duration: 3,
         });
      } catch (err) {
      } finally {
         setIsLoading(false);
      }
   };

   const handleUpdateActiveUntil = async () => {
      if (isInternalAccess(userRole)) {
         if (!date) {
            notification.error({
               message: "Waktu tidak boleh kosong",
               duration: 3,
            });
            return;
         }

         if (
            moment(date).isBefore(new Date(), "day") &&
            (status === "active" || status === "inprogress")
         ) {
            notification.error({
               message: "Waktu tidak boleh lebih kecil dari saat ini",
               duration: 3,
            });
            return;
         }
      }

      if (moment(tempDate).diff(date, "day") || status !== tempStatus || tempDate == null) {
         setShowModalReason(true);
         return;
      }

      onUpdate();
   };

   const {
      list: noChainPharmacies,
      isFetching: fetchPharmacyNoChainLoading,
      refetch: refetchNoChain,
   } = useGetPharmacyList(
      {
         type: companyTypeParam,
         is_chain: false,
         pageSize: -1,
      },
      companyTypeParam === "pharmacy"
   );

   const pharmacyWithNoChain = useMemo(() => {
      const dataReduce = noChainPharmacies?.reduce(
         (collectData: { label: string; value: string | number }[], item: any) => [
            ...collectData,
            { label: `${item.name}, ${item.city_name}`, value: item.id },
         ],
         []
      );

      return {
         loading: fetchPharmacyNoChainLoading,
         data: [{ label: "Not select yet", value: "", disabled: true }, ...dataReduce],
      };
   }, [fetchPharmacyNoChainLoading, noChainPharmacies]);

   const columns = [
      {
         title: "Tgl request",
         dataIndex: "reqDate",
         key: "reqDate",
         sorter: {
            compare: (a: any, b: any) => {
               const aValue =
                  a.reqDate !== "-" ? moment(a.reqDate).diff(moment(), "minutes") * -1 : 0;
               const bValue =
                  b.reqDate !== "-" ? moment(b.reqDate).diff(moment(), "minutes") * -1 : 0;

               return bValue + aValue;
            },
            multiple: 1,
         },
         render: (data: any, row: any) => {
            const date = moment(row.reqDate).format("DD MMM YYYY HH:mm");
            return <>{date}</>;
         },
      },
      {
         title: "Nama",
         dataIndex: "name",
         key: "name",
         render: (data: any, row: any) => {
            let chainLevel = row?.status_chain === "branch" ? "Child" : "Parent";
            return (
               <p>
                  {row.id} <br /> {row.name}
                  <br />
                  <span style={{ color: "silver" }}>
                     {row.group_name ? `${chainLevel} of ${row.group_name}` : ""}
                  </span>
               </p>
            );
         },
      },
      {
         title: "Referal",
         dataIndex: "referral",
         key: "referral",
         render: (data: any, row: any) => {
            if (!row.referral.name && !row.referral.phone_number && !row.referral.email) {
               return null;
            }

            return (
               <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                  <li>{row.referral.name || "-"} </li>
                  <li>{row.referral.email || "-"} </li>
                  <li>{row.referral.phone_number || "-"} </li>
               </ul>
            );
         },
      },
      {
         title: "Utm",
         dataIndex: "utm",
         key: "utm",
         render: (data: any, row: any) => {
            if (!row.utm.souce && !row.utm.medium && !row.utm.campaign) {
               return null;
            }

            return (
               <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                  <li>s: {row.utm.source || "-"} </li>
                  <li>m: {row.utm.medium || "-"} </li>
                  <li>c: {row.utm.campaign || "-"} </li>
               </ul>
            );
         },
      },
      {
         title: "Internal",
         dataIndex: "is_internal",
         key: "is_internal",
         render: (val: any) => {
            return val ? "Internal" : "";
         },
      },
      {
         title: "Lokasi",
         dataIndex: "city",
         key: "city",
         width: 180,
         render: (_: any, row: any) => {
            return (
               <>
                  {row.city} <br /> {row.province}
               </>
            );
         },
      },
      {
         title: "Pembuat",
         dataIndex: "created_by",
         key: "created_by",
         render: (_: any, row: any) => {
            const date = moment(row.created_at).format("DD MMM YYYY");
            return (
               <>
                  {row.created_by} <br />
                  {date}
               </>
            );
         },
      },

      {
         title: "Pemilik",
         dataIndex: "email",
         key: "email",
         render: (_: any, row: any) => {
            return (
               <>
                  {row.owner} <br /> {row.phoneNumber} <br /> {row.email}
               </>
            );
         },
      },
      {
         title: "Status",
         dataIndex: "activeUntil",
         key: "activeUntil",
         width: 120,
         render: (data: any, row: any) => {
            const date = moment(row.activeUntil.activeUntil).format("DD MMM YYYY");
            return (
               <>
                  {renderStatus(row.status)} <br />
                  {date} <br />
                  {renderPayment(row.is_paid)}
               </>
            );
         },
      },
      {
         title: "Action",
         dataIndex: "activeUntil",
         key: "activeUntil",
         render: (data: any, row: any) => {
            return (
               <Radio.Group>
                  <Radio.Button value='edit' onClick={() => handleSelectPharmacy(row)}>
                     Edit
                  </Radio.Button>
                  {isInternalAccess(userRole) ? (
                     <Radio.Button value='log' onClick={() => handleLogPharmacy(row)}>
                        Log
                     </Radio.Button>
                  ) : null}
                  {params?.type === "pharmacy" && (
                     <ModalCompany
                        pharmacyWithNoChain={pharmacyWithNoChain}
                        getPharmacy={refetch}
                        getOptionSelect={refetchNoChain}
                        data={row}
                     />
                  )}
               </Radio.Group>
            );
         },
      },
   ];

   const renderStatus = (s: any) => {
      switch (s) {
         case "active":
            return "Aktif";
         case "inactive":
            return "Tidak aktif";
         case "inprogress":
            return "Diproses";
      }
   };

   const renderTypeLabel = (type: string, upperFirst: boolean) => {
      switch (type) {
         case CompanyType.pharmacy:
            return upperFirst ? "Apotek" : "apotek";
         case CompanyType.distributor:
            return upperFirst ? "PBF" : "pbf";
         default:
            return upperFirst ? "Apotek" : "apotek";
      }
   };

   const renderDate = (date: string | null, includeHour?: boolean) => {
      const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
      return date ? moment(date).format(format) : "-";
   };

   const renderPayment = (is_paid: number) => {
      if (is_paid === 1) return "Sudah bayar";

      return "Belum bayar";
   };

   const getFormattedList = useMemo(() => {
      let result = filteredList?.reduce((collectData: any, p: Pharmacy) => {
         return [
            ...collectData,
            {
               key: p.id,
               id: p.id,
               reqDate: renderDate(p.request_date, true),
               name: p.name,
               status: p.status,
               activeUntil: { activeUntil: p.active_until, id: p.id },
               city: p.city_name,
               province: p.province_name,
               created_by: p.created_by,
               created_at: p.created_at,
               owner: p.owner_name,
               phoneNumber: p.phone_number,
               email: p.email,
               is_internal: p.is_internal,
               is_daily_active: p.is_daily_active,
               is_paid: p.is_paid,
               is_beta: p.is_beta,
               referral: p.referral,
               status_chain: p.status_chain,
               group_name: p.group_name,
               company_group_id: p.company_group_id,
               is_hidden: p.is_hidden,
               utm: { source: p.utm_source, medium: p.utm_medium, campaign: p.utm_campaign },
            },
         ];
      }, []);
      return result;
   }, [list, filteredList]);

   const onSearch = useCallback(
      (text: string) => {
         setSearch(text);
         setFilter({ ...filter, current: 1 });
      },
      [filter]
   );

   const onStatusChange = (status: string) => {
      setStatus(status);
   };

   const onIsPaid = (e: CheckboxChangeEvent) => {
      setIsPaid(Number(e.target.checked));
   };

   const onIsInternalChange = (e: CheckboxChangeEvent) => {
      setIsInternal(Number(e.target.checked));
   };
   const items = [
      {
         label: "Navigation One",
         key: "mail",
      },
   ];

   return (
      <>
         {modalVisible && (
            <PharmacyForm
               pharmacyId={selectedPharmacy}
               modalVisible={modalVisible}
               isLoading={isLoading}
               showModalReason={showModalReason}
               name={name}
               isInternalAccess={isInternalAccess}
               reason={reason}
               title={title}
               userRole={userRole}
               companyTypeParam={companyTypeParam}
               date={date}
               hideWorkspace={hideWorkspace}
               isInternal={isInternal}
               isPaid={isPaid}
               status={status}
               onStatusChange={onStatusChange}
               onIsPaid={onIsPaid}
               onIsInternalChange={onIsInternalChange}
               setHideWorkspace={setHideWorkspace}
               handleChangeDate={handleChangeDate}
               isExternalAccess={isExternalAcces}
               renderTypeLabel={renderTypeLabel}
               onUpdate={onUpdate}
               setReason={setReason}
               setTitle={setTitle}
               handleCloseModal={handleCloseModal}
               handleUpdateActiveUntil={handleUpdateActiveUntil}
               setShowModalReason={setShowModalReason}
            />
         )}

         {isInternalAccess(userRole) ? (
            <Modal
               visible={modalLogVisible}
               onCancel={handleCloseModal}
               confirmLoading={isLoading}
               width={1000}
               title={<h2>Log {name}</h2>}
            >
               <PharmacyLogComponent company_id={selectedPharmacy as number} />
            </Modal>
         ) : null}
         <Layout style={{ height: "100vh" }}>
            <Sider breakpoint='lg' collapsedWidth='0'>
               <Sidebar activeTab={"pharmacy"} />
            </Sider>
            <Layout>
               <Header style={{ background: "#fff" }}>
                  <h1>List {renderTypeLabel(companyTypeParam, true)}</h1>
               </Header>
               <Content
                  style={{
                     padding: "24px",
                     paddingBottom: "100px",
                     overflowY: "scroll",
                  }}
               >
                  <Row style={{ marginBottom: "16px" }}>
                     <Search
                        placeholder='input search text'
                        onSearch={onSearch}
                        style={{ width: 200 }}
                        enterButton={"Cari"}
                     />
                  </Row>

                  <Loading loading={isFetching && !!(getFormattedList?.length > 0)} />

                  <Menu
                     selectedKeys={[activeTab]}
                     defaultSelectedKeys={[activeTab]}
                     mode='horizontal'
                     style={{ marginBottom: "10px" }}
                  >
                     <Menu.Item key={"active"} onClick={() => setActiveTab("active")}>
                        Aktif
                     </Menu.Item>
                     <Menu.Item key={"hidden"} onClick={() => setActiveTab("hidden")}>
                        Disembunyikan
                     </Menu.Item>
                  </Menu>
                  <Table
                     loading={isFetching && !(getFormattedList?.length > 0)}
                     dataSource={getFormattedList}
                     columns={columns}
                     pagination={{ ...filter, total: totalData, showSizeChanger: false }}
                     onChange={(val) => {
                        setFilter({ ...filter, current: val?.current || 1 });
                     }}
                  />
               </Content>
            </Layout>
         </Layout>
      </>
   );
};

export default PharmacyComponent;

const ModalCompany = (props: {
   data: any;
   getOptionSelect: () => void;
   getPharmacy: () => void;
   pharmacyWithNoChain: { loading: boolean; data: any };
}) => {
   const [show, setShow] = useState(false);

   return (
      <React.Fragment>
         <Button
            // value="company"
            type={!!props.data?.group_name ? "primary" : "default"}
            size='middle'
            onClick={() => setShow(true)}
         >
            Chain
         </Button>
         {show && <ModalCompanyContainer {...props} show={show} setShow={setShow} />}
      </React.Fragment>
   );
};

const ModalCompanyContainer = (props: ModalCompanyContainerType) => {
   const { data, getPharmacy, show, setShow, pharmacyWithNoChain, getOptionSelect } = props;
   const [loading, setLoading] = useState(false);
   //----- GET COMPANY CHAIN AND NOT CHAIN ----------
   const [companyList, setCompanyList] = useState({
      loading: false,
      chain: [],
   });

   const fetchChain = async () => {
      setCompanyList({ ...companyList, loading: true });
      await getDetailChain(data?.company_group_id).then((response) => {
         setCompanyList({ loading: false, chain: response?.collection });
      });
   };

   useEffect(() => {
      if (data?.company_group_id) {
         fetchChain();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data?.company_group_id]);

   const initialFormGroups = {
      main_company_id: data?.id,
      company_group: data?.group_name || "",
      company_group_id: data?.company_group_id ? data?.company_group_id : undefined,
   };
   const initialFormMember: FormMemberType = {
      branch_name: "",
      province_id: "",
      city_id: "",
      branch_company_id: "",
      isNew: "",
   };

   const defaultFormMember = companyList?.chain?.length > 0 ? [] : [initialFormMember];
   const [formGroups, setFormGroups] = useState(initialFormGroups);
   const [formMember, setFormMember] = useState<FormMemberType[]>(defaultFormMember);

   const form = {
      group: {
         act: setFormGroups,
         data: formGroups,
         default: initialFormGroups,
      },
      member: {
         act: setFormMember,
         data: formMember,
         default: initialFormMember,
      },
   };
   const onCancel = () => {
      if (!loading) {
         setLoading(false);
         setFormGroups(initialFormGroups);
         setFormMember(defaultFormMember);
         setShow(false);
         if (formMember?.filter((res) => res?.progress === "success")?.length > 0) {
            getPharmacy();
            getOptionSelect();
         }
      }
   };

   const formMemberFilter = formMember.filter(
      (item) =>
         (!!item?.branch_name && !!item?.city_id && !!item?.province_id) ||
         !!item?.branch_company_id
   );

   const sendData = async (index: number, groupID?: string, failed = 0, success = 0) => {
      let dataNewBranch = {
         ...formMember[index],
         ...formGroups,
         company_group_id: formGroups?.company_group_id || groupID,
      };
      let dataWithExistingData = {
         ...formMember[index],
         ...formGroups,
         company_group_id: formGroups?.company_group_id || groupID,
         branch_company_id: formMember[index]?.branch_company_id,
         branch_name: "-",
         province_id: "-",
         city_id: "-",
         isNew: undefined,
      };
      let dataToSend = !!formMember[index]?.branch_company_id
         ? dataWithExistingData
         : dataNewBranch;
      setLoading(true);
      if (dataToSend?.progress !== "success") {
         let duplicateFormMember = formMember;
         duplicateFormMember[index] = {
            ...formMember[index],
            progress: "loading",
         };
         setFormMember([...duplicateFormMember]);
         await addOrCreateChainGroups(dataToSend)
            .then(async (res) => {
               const response = res.data;
               let duplicateFormMember = formMember;
               setFormGroups({
                  ...formGroups,
                  company_group_id: response?.company_group_id,
               });
               duplicateFormMember[index] = {
                  ...formMember[index],
                  progress: "success",
               };
               setFormMember([...duplicateFormMember]);
               success = success + 1;
               if (formMember.length === index + 1) {
                  setLoading(false);
                  if (success === index + 1) {
                     notification.success({
                        message: `${index + 1} Pharmacies successfully created`,
                        placement: "topRight",
                        duration: 2,
                     });
                     onCancel();
                  }
               } else {
                  setTimeout(async () => {
                     await sendData(
                        index + 1,
                        groupID || response?.company_group_id,
                        failed,
                        success
                     );
                  }, 1000);
               }
            })
            .catch(async (error) => {
               duplicateFormMember[index] = {
                  ...formMember[index],
                  progress: "failed",
                  message: error?.response?.data?.message || "Create failed`",
               };
               setFormMember([...duplicateFormMember]);
               failed = failed + 1;
               if (formMember.length === index + 1) {
                  setLoading(false);
               } else {
                  setTimeout(async () => {
                     await sendData(index + 1, groupID, failed, success);
                  }, 1000);
               }
            });
      } else {
         sendData(index + 1, groupID, failed, success + 1);
      }
   };

   return (
      <React.Fragment>
         <Modal
            okButtonProps={{ title: "Save" }}
            visible={show}
            onCancel={onCancel}
            footer={[
               <Button disabled={loading} key='back' size='large' onClick={onCancel}>
                  Close
               </Button>,
               <Button
                  loading={loading}
                  onClick={() => sendData(0)}
                  disabled={
                     formMemberFilter?.length === 0 ||
                     formMemberFilter?.length !== formMember?.length ||
                     !formGroups?.company_group
                  }
                  key='submit'
                  size='large'
                  type='primary'
               >
                  Save
               </Button>,
            ]}
            width={"90%"}
            title={<h2>Company Chain Group</h2>}
         >
            <BodyModalCompany
               pharmacyWithNoChain={pharmacyWithNoChain}
               chainData={companyList?.chain}
               groupName={data?.group_name}
               data={data}
               form={form}
            />
         </Modal>
      </React.Fragment>
   );
};

const BodyModalCompany = (props: BodyModalCOmpanyType) => {
   const { data, form, chainData, groupName, pharmacyWithNoChain } = props;
   const [optionData, setOptionData] = useState([]);

   const getRegionData = async () => {
      await getRegionList().then((response) => {
         const dataRes = response.reduce((collect: any, item: any) => {
            const option = {
               value: { city_id: item?.city_id, province_id: item?.province_id },
               label: item.region_name,
            };
            return [...collect, option];
         }, []);
         setOptionData(dataRes);
      });
   };

   useEffect(() => {
      getRegionData();
   }, []);

   const sortChainData = chainData?.sort((a: any, b: any) => {
      if (a.status_chain !== b.status_chain) {
         return a.status_chain < b.status_chain ? 1 : -1;
      } else {
         return a.id < b.id ? 1 : -1;
      }
   });

   const chainThatAlreadyInForm = form?.member?.data?.reduce(
      (ids: (string | number)[], item: any) => {
         if (item?.branch_company_id) {
            return [...ids, item.branch_company_id];
         } else {
            return ids;
         }
      },
      []
   );

   return (
      <div>
         <div></div>
         {!data?.status_chain && (
            <Alert message={`${data?.name} will be parrent of this group`} type='info' showIcon />
         )}
         <Row style={{ alignItems: "center", padding: "5px 0 5px 0", gap: "30px" }}>
            <Col span={8}>
               <b>Group Name :</b>
               <div>
                  {groupName ? (
                     <p
                        style={{
                           border: "solid 0.3px",
                           padding: "5px",
                           borderRadius: "3px",
                        }}
                     >
                        {groupName}
                     </p>
                  ) : (
                     <Input
                        disabled={!!groupName}
                        value={form.group.data?.company_group}
                        onChange={(e) =>
                           form.group.act({
                              ...form.group.data,
                              company_group: e.target.value,
                           })
                        }
                     />
                  )}
               </div>
            </Col>
         </Row>
         <hr />
         <Row style={{ padding: "5px 0 5px 0", background: "#f0f0f0f0" }}>
            {sortChainData?.map((item: any, index: number) => (
               <Col span={8} style={{ padding: "10px" }} key={`already-exist-${index}`}>
                  <MemberAlreadyAdded data={item} />
               </Col>
            ))}
            {(!data?.status_chain || data?.status_chain === "main") &&
               form?.member?.data?.map((el: any, index: number) => (
                  <Col span={8} key={index} style={{ padding: "10px" }}>
                     <MemberForm
                        idCOmpany={data?.id}
                        chainThatAlreadyInForm={chainThatAlreadyInForm}
                        pharmacyWithNoChain={pharmacyWithNoChain}
                        optionData={optionData}
                        fullData={form.member?.data}
                        data={el}
                        index={index}
                        formHandle={form.member?.act}
                     />
                  </Col>
               ))}
            {(!data?.status_chain || data?.status_chain === "main") && (
               <Col span={8} style={{ padding: "10px" }}>
                  <div
                     style={{
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                     }}
                  >
                     <Button
                        type='primary'
                        shape='round'
                        size={"large"}
                        onClick={() =>
                           form.member.act([...form?.member?.data, form.member?.default])
                        }
                     >
                        + Add Other Branch
                     </Button>
                  </div>
               </Col>
            )}
         </Row>
      </div>
   );
};

const MemberForm = (props: MemberFormType) => {
   const {
      optionData,
      formHandle,
      index,
      data,
      idCOmpany,
      fullData,
      pharmacyWithNoChain,
      chainThatAlreadyInForm,
   } = props;

   const isSuccess = data?.progress === "success";
   const loading = data?.progress === "loading";
   const isFail = data?.progress === "failed";
   const handleData = (e: { name: string; value: any }) => {
      const { name, value } = e;
      const objectData = { [name]: value };
      let replicateData = fullData;
      if (name === "branch_company_id") {
         replicateData[index] = { ...objectData, isNew: data?.isNew };
      } else if (name === "city_id") {
         let province_id = optionData?.filter((element) => element?.value?.city_id === value)?.[0]
            ?.value?.province_id;
         replicateData[index] = {
            ...replicateData[index],
            ...objectData,
            province_id,
         };
      } else {
         replicateData[index] = { ...replicateData[index], ...objectData };
      }
      formHandle([...replicateData]);
   };

   const handleCancel = () => {
      const replicateData = fullData;
      formHandle(replicateData.filter((el: any, idx: number) => idx !== index));
   };

   const optionDataCity = optionData.reduce(
      (item: any, el) => [...item, { ...el, value: el.value.city_id }],
      [{ value: "", label: "Not Select yet", disabled: true }]
   );

   const optionDataPharmacy = pharmacyWithNoChain?.data?.reduce((items: any, item) => {
      let newItem = item;
      if (idCOmpany === item.value) {
         return items;
      }

      if (chainThatAlreadyInForm?.includes(item.value)) {
         newItem = { ...newItem, disabled: true };
      }

      return [...items, newItem];
   }, []);

   const optionEarly = [
      { value: "", label: "Select pharmacy", disabled: true },
      { value: "yes", label: "New Pharmacy" },
      { value: "no", label: "Existing Pharmacy" },
   ];

   const select = {
      label: "Select New/Existing Pharmacy",
      render: (
         <SelectInput
            disabled={isSuccess || pharmacyWithNoChain.loading}
            value={data.isNew}
            onChange={(e) => handleData({ name: "isNew", value: e })}
            optionData={optionEarly}
         />
      ),
   };
   const firstForm = {
      label: "Select Existing Pharmacy",
      render: (
         <SelectInput
            disabled={isSuccess || pharmacyWithNoChain.loading}
            value={data.branch_company_id}
            onChange={(e) => handleData({ name: "branch_company_id", value: e })}
            optionData={optionDataPharmacy || []}
         />
      ),
   };
   const secondaryForm = [
      {
         label: "Branch Name",
         render: (
            <Input
               disabled={isSuccess}
               value={data?.branch_name}
               onChange={(e) => handleData({ name: "branch_name", value: e.target.value })}
            />
         ),
      },
      {
         label: "City",
         render: (
            <SelectInput
               disabled={isSuccess}
               value={data.city_id}
               onChange={(e) => handleData({ name: "city_id", value: e })}
               optionData={optionDataCity}
            />
         ),
      },
   ];

   const generateForm =
      data.isNew === "no"
         ? [select, firstForm]
         : data.isNew === "yes"
         ? [select, ...secondaryForm]
         : [select];
   return (
      <div
         style={{
            border: "solid #eaeaea",
            height: "100%",
            borderWidth: "0.5px",
            padding: "10px",
            borderRadius: "5px",
            background: "white",
         }}
      >
         <h4 style={{ borderBottom: "solid 0.5px" }}>Branch {index + 1}</h4>
         {generateForm?.map((item, index) => (
            <div style={{ padding: "10px" }} key={index}>
               <span>{item.label} :</span>
               <div>{item.render}</div>
            </div>
         ))}
         <div style={{ display: "flex", justifyContent: "end", padding: "10px" }}>
            {isSuccess ? (
               <Alert
                  style={{ width: "100%" }}
                  message={`Create was successfully`}
                  type='success'
                  showIcon
               />
            ) : isFail ? (
               <Alert style={{ width: "100%" }} message={data?.message} type='error' showIcon />
            ) : loading ? (
               <Spin />
            ) : (
               <Button type='primary' danger size={"small"} onClick={handleCancel}>
                  Cancel
               </Button>
            )}
         </div>
      </div>
   );
};

const SelectInput = (props: SelectAddressType) => {
   const { optionData, onChange, value, disabled } = props;

   return (
      <Select
         showSearch
         disabled={disabled}
         filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
         }
         value={value}
         style={{ width: "100%" }}
         onChange={(e) => onChange(e)}
      >
         {optionData?.map((item, index) => (
            <Option disabled={item?.disabled} key={index} value={item?.value}>
               {item.label}
            </Option>
         ))}
      </Select>
   );
};

const MemberAlreadyAdded = (props: { data: any }) => {
   const { data } = props;

   const geneRateData = [
      {
         label: "Branch name",
         render: `${data?.name}`,
      },
      { label: "status", render: data?.status },
      { label: "City", render: `${data?.city}, ${data?.province}` },
   ];
   return (
      <div
         style={{
            border: "solid #eaeaea",
            borderWidth: "0.5px",
            padding: "10px",
            height: "100%",
            borderRadius: "5px",
            flex: 1,
            background: "white",
         }}
      >
         <h4 style={{ borderBottom: "solid 0.5px" }}>
            {data.status_chain === "main" ? "Parent" : "Child"}
         </h4>
         {geneRateData?.map((item, index) => (
            <div style={{ padding: "10px" }} key={index}>
               <span style={{ fontSize: "12px" }}>{item.label} :</span>
               <div>{item.render}</div>
            </div>
         ))}
      </div>
   );
};

const Loading = ({ loading }: { loading: boolean }) => {
   if (loading) {
      return (
         <div
            style={{
               position: "fixed",
               width: "100%",
               zIndex: 2,
               display: "flex",
               justifyContent: "center",
            }}
         >
            <Spin indicator={antIcon} size='large' />
         </div>
      );
   } else {
      return null;
   }
};

type PharmacyWithNoChainType = {
   loading: boolean;
   data: { label: string; value: any; disabled?: boolean }[];
};

type ModalCompanyContainerType = {
   data: any;
   getPharmacy: () => void;
   show: boolean;
   setShow: (e: boolean) => void;
   pharmacyWithNoChain: PharmacyWithNoChainType;
   getOptionSelect: () => void;
};

type FormMemberType = {
   branch_name: string;
   province_id: string;
   city_id: string;
   message?: string;
   progress?: "success" | "loading" | "failed";
   id?: string;
   branch_company_id?: string | number;
   isNew?: "" | "yes" | "no";
};

type BodyModalCOmpanyType = {
   data: any;
   form: { group: any; member: any };
   chainData: any;
   groupName: string;
   pharmacyWithNoChain: PharmacyWithNoChainType;
};

type MemberFormType = {
   optionData: { label: any; value: any }[];
   data: any;
   fullData: any;
   index: number;
   formHandle: any;
   isSuccess?: boolean;
   pharmacyWithNoChain: PharmacyWithNoChainType;
   chainThatAlreadyInForm: (string | number)[];
   idCOmpany?: number | string;
};
type SelectAddressType = {
   value: any;
   optionData: { label: any; value: any; disabled?: boolean }[];
   onChange: (e: any) => void;
   disabled?: boolean;
};
