import React from "react";
import { Switch, Route } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import { RedirectRoute } from "./RedirectRoute";

// component
import Pharmacy from "../pages/Pharmacy/Pharmacy";
import ExportData from "../pages/ExportData/ExportData";
import PriceHistory from "../pages/PriceHistory/PriceHistory";
import Logger from "../pages/Logger/Logger";
import SignInPage from "../pages/Login/Login";
import User from "../pages/User/User";
import DataAnomaly from "../pages/DataAnomaly/DataAnomaly";
import Analytics from "../pages/Analytics/Analytics";
import PharmaciesConversion from "../pages/PharmaciesConversion/PharmaciesConversion";
import MonitorLambda from "../pages/MonitorLambda/MonitorLambda";
import TermsCondition from "../pages/TermsCondition";
import MonitorSystemResources from "../pages/MonitorSystemResources/MonitorSystemResources";
import FarmacareCatalog from "../pages/FarmacareCatalog";
import SubscriptionPackage from "../pages/SubscriptionPackage/SubscriptionPackage";
import PaidSubscription from "../pages/PaidSubscription/PaidSubscription";
import AccessControl from "../pages/AccessControl";
import PromotionDefaultPage from "../pages/Promotion/Promotion";
import { TaxReportHistory } from "../pages/TaxReportHistory/TaxReportHistory/TaxReportHistory";
import { TaxReportHistoryDetail } from "../pages/TaxReportHistory/TaxHistoryReportDetail/TaxReportHistoryDetail";

const MainRoutes = () => {
   return (
      <Switch>
         <RedirectRoute exact path='/' />
         <PrivateRoute exact path='/company/:type' component={Pharmacy} />
         <PrivateRoute exact path='/export-data' component={ExportData} />
         <PrivateRoute exact path='/tax-report-history' component={TaxReportHistory} />
         <PrivateRoute
            exact
            path='/tax-report-history/:productId'
            component={TaxReportHistoryDetail}
         />
         <PrivateRoute exact path='/price-history' component={PriceHistory} />
         <PrivateRoute exact path='/logger' component={Logger} />
         <PrivateRoute exact path='/user' component={User} />
         <PrivateRoute exact path='/analytics' component={Analytics} />
         <PrivateRoute exact path='/pharmacies-conversion' component={PharmaciesConversion} />
         <PrivateRoute exact path='/tnc' component={TermsCondition} />
         <PrivateRoute exact path='/fc-catalog' component={FarmacareCatalog} />

         <PrivateRoute exact path='/data-anomaly' component={DataAnomaly} />
         <PrivateRoute exact path='/monitor-lambda' component={MonitorLambda} />
         <PrivateRoute exact path='/monitor-system-resources' component={MonitorSystemResources} />
         <PrivateRoute exact path='/subscription-package' component={SubscriptionPackage} />
         <PrivateRoute exact path='/paid-subscription' component={PaidSubscription} />
         <PrivateRoute exact path='/promotion' component={PromotionDefaultPage} />
         <PrivateRoute exact path='/access-control*' component={AccessControl} />
         <Route exact path='/sign-in' component={SignInPage} />
         <Route component={() => <div>Halaman tidak ditemukan</div>} />
      </Switch>
   );
};

export default MainRoutes;
