import React from "react";
import { Row, Col, Collapse } from "antd";
import moment from "moment";

// type
import { Logger } from "../../types/logger";
type Props = {
  selectedLogger: Logger;
};

const { Panel } = Collapse;

const LoggerDetail = (props: Props) => {
  const { selectedLogger } = props;

  const renderDate = (
    date: string | null | undefined,
    includeHour?: boolean
  ) => {
    const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
    return date ? moment(date).format(format) : "-";
  };

  const renderJson = (str: string) => {
    try {
      return JSON.stringify(JSON.parse(str), null, 2);
    } catch (e) {
      return str;
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Detail Log</h2>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          DateTime: {renderDate(selectedLogger?.created_at, true)}
        </Col>
        <Col span={6}>Level: {selectedLogger?.log_level}</Col>
      </Row>
      <Row>
        <Col span={24}>
          Host: {selectedLogger?.host}
          {selectedLogger?.endpoint} - {selectedLogger?.method} -{" "}
          {selectedLogger?.duration}ms
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          Apotek: {selectedLogger?.company_id} - {selectedLogger?.company_name}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          Pengguna: {selectedLogger?.created_by} - {selectedLogger?.username}
        </Col>
      </Row>
      <Row>
        <Col span={6}>Response Code: {selectedLogger?.response_code}</Col>
        <Col span={18}>Message: {selectedLogger?.message}</Col>
      </Row>
      <Row>
        <Col span={24}>
          Pengguna: {selectedLogger?.created_by} - {selectedLogger?.username}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["2", "3", "5"]}>
            <Panel header="Request Header" key="1">
              <Row>
                <Col span={24}>
                  <pre> {renderJson(selectedLogger.request_header)}</pre>
                </Col>
              </Row>
            </Panel>
            <Panel header="Request Body" key="2">
              <Row>
                <Col span={24}>
                  <pre>{renderJson(selectedLogger.request_body)}</pre>
                </Col>
              </Row>
            </Panel>
            <Panel header="Payload" key="3">
              <Row>
                <Col span={24}>
                  <pre>{renderJson(selectedLogger.payload)}</pre>
                </Col>
              </Row>
            </Panel>
            <Panel header="Response Header" key="4">
              <Row>
                <Col span={24}>
                  <pre>{renderJson(selectedLogger.response_header)}</pre>
                </Col>
              </Row>
            </Panel>
            <Panel header="Response Body" key="5">
              <Row>
                <Col span={24}>
                  <pre>{renderJson(selectedLogger.request_body)}</pre>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default LoggerDetail;
