import React, { useEffect, useMemo, useState } from "react";
import { Layout, Table, Modal, Row, Col, DatePicker, Input, Select } from "antd";

// utils
import {
  getProductList,
} from "./utils/getPriceHistory";

// type
import { Product, defaultPriceHistory, defaultProduct } from "../../types/priceHistory";

// component
import Sidebar from "../../component/Sidebar";
import moment from "moment";
import { debounce } from "lodash";
import getPharmacyList from "../Pharmacy/utils/getPharmacyList";
import PriceHistoryDetailComponent from "./PriceHistoryDetail";
// import PriceHistoryLogComponent from "./PriceHistoryLog";



const PriceHistoryComponent = () => {
  const { Header, Content, Sider } = Layout;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [companyCollection, setCompanyCollection] = useState([
    { id: 0, name: "Pilih Apotek" },
  ]);
  const [companyList, setCompanyList] = useState([{ id: 0, name: "Pilih Apotek" }]);

  const [list, setList] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] =
    useState({company_id: 0, product_name: ""});
  const [selectedProduct, setSelectedProduct] =
    useState<Product>(defaultProduct);
  const [modalVisible, setModalVisible] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 50,
  });

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    try {
      setIsLoading(true);
      const listPriceHistory = await getProductList({
        ...filter,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...pagination,
        ...filters      });
      setList(listPriceHistory.collections);
      setPagination({
        ...pagination,
        total: listPriceHistory.total_data,
      });
      setIsLoading(false);
    } catch (err) { }
  };

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Stok",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Harga Jual",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Harga Pokok",
      dataIndex: "price_per_unit",
      key: "price_per_unit",
    },
  ];

  const getProductData = async () => {
    try {
      setIsLoading(true);
      const listPriceHistory = await getProductList({
        ...pagination,
        ...filter
      });
      setList(listPriceHistory.collections);
      setPagination({
        ...pagination,
        total: listPriceHistory.total_data,
      });
      setIsLoading(false);
    } catch (err) { }
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case "lived":
        return "Live";
      case "downed":
        return "Down";

      default:
        return status;
    }
  };

  const renderDate = (
    date: string | null | undefined,
    includeHour?: boolean
  ) => {
    const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
    return date ? moment(date).format(format) : "-";
  };

  const getFormattedList = useMemo(() => {
    let result: Product[] = list.map((p: Product) => {
      return {
        id: p.id,
        name: p.name,
        price: p.price,
        unit: p.unit,
        price_per_unit: p.price_per_unit
      };
    });

    return result;
  }, [list, filter]);

  useEffect(() => {
    getProductData();
  }, [filter]);

  const onSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }, 800);

  const handleSelectedProduct = async (record: any) => {
    setModalVisible(true);
    setSelectedProduct(record);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const onSelectCompanyChange = (value: number, option: any) => {
    setSelectedCompanyName(option?.children);
    setFilter({...filter, company_id: value})
  };

  const onSearchCompany = (value: string) => {
    const searchCompany = companyCollection.filter((v, i) => {
      const reg = new RegExp(value.toLowerCase(), "g");
      if (v.name.toLocaleLowerCase().match(reg)) {
        return true;
      }

      return false;
    });

    setCompanyList(searchCompany);
  };

  const getCompany = async () => {
    const response = await getPharmacyList();
    setCompanyCollection(response);
    setCompanyList(response);
  };

  useEffect(() => {
    getCompany();
  }, []);

  const optionCompany = () =>
    companyList.map((d) => (
      <Option key={d.id} value={d.id}>
        {d.name}
      </Option>
    ));

  return (
    <>
      <Modal
        visible={modalVisible}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        confirmLoading={isLoading}
        width={1000}
      >
        <PriceHistoryDetailComponent product_id={selectedProduct.id} />
      </Modal>

      <Layout style={{ height: "100vh" }}>
        <Sider breakpoint="lg" collapsedWidth="0">
          <Sidebar activeTab={"price-history"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>Price History</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Row style={{ marginBottom: "16px" }} gutter={16}>
              <Col span={8}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  showArrow={false}
                  filterOption={false}
                  onChange={onSelectCompanyChange}
                  onSearch={onSearchCompany}
                  notFoundContent={null}
                  placeholder="Pilih Apotek"
                >
                  {optionCompany()}
                </Select>
              </Col>
              <Col span={8}>
                <Input
                  name="product_name"
                  placeholder="Cari product"
                  onChange={onSearch}
                />
              </Col >
            </Row>
            <Table
              rowKey={(record) => record.id}
              dataSource={getFormattedList}
              columns={columns}
              pagination={pagination}
              loading={isLoading}
              onChange={handleTableChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleSelectedProduct(record);
                  }, // click row
                };
              }}
            />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default PriceHistoryComponent;
