import { notification } from "antd";

import axios from '../../../config/axios'

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const getCompanyUser = async (company_id: number) => {
  try {
    let queryParams = new URLSearchParams({
      company_id: company_id,
    } as any).toString();
    
    const url = `${BASE_URL_BETA}/user?${queryParams}`;
    const response = await axios.get(url);

    if (response && response.status === 200) {
        return response.data.list_user
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
    // throw new Error(err);
  }
};

export default getCompanyUser;