import React from "react";

import "antd/dist/antd.css";
import "./App.css";

import MainRoute from "./routes";

function App() {
   return <MainRoute />;
}

export default App;
