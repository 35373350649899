export interface DataAnomaly {
  id: number;
  data_type: "stock_card";
  label: string;
  query: string;
  status: boolean;
  created_at: string;
}

export const defaultDataAnomaly: DataAnomaly = {
  id: 0,
  data_type: "stock_card",
  label: "",
  query: "",
  status: false,
  created_at: "",
};

export interface DataAnomalyDetail {
  id: number;
  pharmacy_id: number;
  pharmacy_name: string;
  product_id: number;
  product_name: string;
}

export const defaultDataAnomalyDetail: DataAnomalyDetail[] = [
  {
    id: 0,
    pharmacy_id: 0,
    pharmacy_name: "",
    product_id: 0,
    product_name: "",
  },
];
