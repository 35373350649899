export enum StockActionType {
  /**
   * inisiasi stok awal
   */
  "ANULL" = "null",
  /**
   * inisiasi stok awal
   */
  "A000" = "0",
  /**
   * Penjualan mengurangi stok, transaksi normal :) kita mengurangi stok
   */
  "A111" = "111",
  /**
   * retur dari penjualan, kita menambah stok, edit transaksi
   */
  "A122" = "122",
  /**
   * retur penjualan
   */
  "A131" = "131",
  /**
   * retur penjualan setelah update stok
   */
  "A132" = "132",
  /**
   * batal penjualan, menambah stok
   */
  "A133" = "133",
  /**
   * penerimaan dari faktur, normal :) menambah stok
   */
  "A211" = "211",
  /**
   *  edit faktur yang menambah stok
   */
  "A221" = "221",
  /**
   * edit faktur yang mengurangi stok
   */
  "A222" = "222",
  /**
   * retur faktur
   */
  "A231" = "231",
  /**
   * batal faktur semua
   */
  "A232" = "232",
  /**
   * update stok inventori menambahkan stok
   */
  "A311" = "311",
  /**
   * update stok inventori mengurangi stok
   */
  "A312" = "312",
  /**
   * stok opname menambahkan stok
   */
  "A411" = "411",
  /**
   * stok opname mengurangi stok
   */
  "A412" = "412",
  /**
   * stok opname mengurangi stok
   */
  "A413" = "413",
  /**
   * mutasi stok, mengurangi stok
   */
  "A511" = "511",
  /**
   * mutasi stok, menambahkan stok
   */
  "A512" = "512",
  /**
   * Perubahan HPS barang dikasir
   */
  "A911" = "911",
  /**
   * Perubahan HPS karena di-override
   */
  "A912" = "912",
}
