import { Button, Checkbox, Col, DatePicker, Divider, Input, Modal, Row, Select, Tag } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import TextArea from "antd/lib/input/TextArea";
import { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { PharmacyAddOnList } from "./PharmacyAddOns/PharmacyAddOnList";
import { getActiveAddOns } from "./PharmacyAddOns/utils/getActiveAddOns";
import { AddOn } from "./PharmacyAddOns/utils/interface";

export interface Props {
   modalVisible: boolean;
   isLoading: boolean;
   name: string;
   pharmacyId: string | number | null;
   showModalReason: boolean;
   reason: string;
   title: string;
   userRole: string;
   companyTypeParam: any;
   date: null | Moment;
   hideWorkspace: boolean;
   isInternal: number;
   isPaid: number;
   status: string;
   onStatusChange: (value: string) => void;
   onIsPaid: (e: CheckboxChangeEvent) => void;
   onIsInternalChange: (e: CheckboxChangeEvent) => void;
   setHideWorkspace: (val: boolean) => void;
   handleChangeDate: (date: null | Moment) => void;
   isExternalAccess: (val: string) => boolean;
   isInternalAccess: (val: string) => boolean;
   onUpdate: () => void;
   renderTypeLabel: (val: any, val1: boolean) => string;
   setShowModalReason: (val: boolean) => void;
   setTitle: (val: string) => void;
   setReason: (val: string) => void;
   handleUpdateActiveUntil: () => void;
   handleCloseModal: () => void;
}

const PharmacyForm = ({
   modalVisible,
   isLoading,
   showModalReason,
   pharmacyId,
   name,
   reason,
   title,
   userRole,
   companyTypeParam,
   date,
   hideWorkspace,
   isInternal,
   isPaid,
   status,
   isInternalAccess,
   onStatusChange,
   onIsPaid,
   onIsInternalChange,
   setHideWorkspace,
   handleChangeDate,
   isExternalAccess,
   renderTypeLabel,
   onUpdate,
   setReason,
   setTitle,
   handleCloseModal,
   handleUpdateActiveUntil,
   setShowModalReason,
}: Props) => {
   const [activeAddOns, setActiveAddOns] = useState<AddOn[]>([]);
   const [showAddOnList, setShowAddOnList] = useState(false);
   const [loadingAddOn, setLoadingAddOn] = useState(false);

   const getAddOnsByCompanyId = async () => {
      setLoadingAddOn(true);
      const addOnList = await getActiveAddOns(pharmacyId);
      setActiveAddOns(addOnList || []);
      setLoadingAddOn(false);
   };

   useEffect(() => {
      getAddOnsByCompanyId();
   }, []);

   const renderAddOns = useMemo(() => {
      if (loadingAddOn) {
         return <h4>Memuat...</h4>;
      }

      if (!activeAddOns.length) {
         return <h4>Tidak ada add-on yang aktif</h4>;
      }

      return (activeAddOns || []).map((a, index) => {
         return (
            <Tag key={`active-add-on-${index}`}>
               <b>{a.name || ""} </b>
            </Tag>
         );
      });
   }, [loadingAddOn, activeAddOns]);

   return (
      <>
         {showAddOnList && (
            <PharmacyAddOnList
               pharmacyId={pharmacyId!}
               refetch={() => getAddOnsByCompanyId()}
               onCloseModal={() => setShowAddOnList(false)}
               addOnList={activeAddOns}
            />
         )}

         <Modal
            visible={modalVisible && !showAddOnList}
            onCancel={handleCloseModal}
            onOk={handleUpdateActiveUntil}
            confirmLoading={isLoading}
            width={1000}
            title={<h2>Edit {name}</h2>}
         >
            <Modal
               visible={showModalReason}
               onCancel={() => {
                  setShowModalReason(false);
                  setReason("");
                  setTitle("");
               }}
               onOk={() => onUpdate()}
               title={`Detail perubahan aktivasi ${renderTypeLabel(companyTypeParam, false)}`}
               okButtonProps={{ disabled: !reason || !title }}
            >
               <Col>
                  <h4>Judul perubahan</h4>
                  <Input onChange={(e) => setTitle(e.target.value)} value={title} />
               </Col>
               <Col style={{ marginTop: "16px" }}>
                  <h4>Deskripsi perubahan</h4>
                  <TextArea value={reason} onChange={(e) => setReason(e.target.value)} />
               </Col>
            </Modal>

            <Row>
               <Col md={24}>
                  <Row>
                     {isInternalAccess(userRole) ? (
                        <Col md={12}>
                           <h3>Pilih waktu batas aktivasi</h3>
                           <DatePicker
                              format='YYYY-MM-DD'
                              onChange={handleChangeDate}
                              value={date?.isValid() ? date : undefined}
                           />
                        </Col>
                     ) : null}
                     {isExternalAccess(userRole) ? (
                        <Col md={12}>
                           <h3>Pilih Status</h3>
                           <Select value={status} style={{ width: 180 }} onChange={onStatusChange}>
                              <Select.Option value='active' key='active'>
                                 Aktif
                              </Select.Option>
                              <Select.Option value='inactive' key='inactive'>
                                 Tidak Aktif
                              </Select.Option>
                           </Select>
                        </Col>
                     ) : null}
                  </Row>
                  <Divider />

                  <Row>
                     <Col md={24}>
                        <Row>
                           <h3>Add-on yang aktif</h3>
                        </Row>
                     </Col>

                     <Col md={24}>
                        <div style={{ flexWrap: "wrap" }}>{renderAddOns}</div>
                     </Col>

                     <Col
                        md={24}
                        style={{
                           marginTop: "20px",
                        }}
                     >
                        <Button onClick={() => setShowAddOnList(true)}> Edit</Button>
                     </Col>
                  </Row>

                  <Divider />
                  {isInternalAccess(userRole) ? (
                     <Col md={12}>
                        <h3>Apakah {renderTypeLabel(companyTypeParam, false)} ini sudah bayar?</h3>
                        <Row>
                           <Checkbox onChange={onIsPaid} checked={Boolean(isPaid)}>
                              Iya sudah!
                           </Checkbox>
                        </Row>
                     </Col>
                  ) : null}
                  <Divider />
                  {isInternalAccess(userRole) ? (
                     <Col md={12}>
                        <h3>
                           Apakah merupakan {renderTypeLabel(companyTypeParam, false)} demo atau
                           test?
                        </h3>
                        <Row>
                           <Checkbox onChange={onIsInternalChange} checked={Boolean(isInternal)}>
                              Iya benar!
                           </Checkbox>
                        </Row>
                     </Col>
                  ) : null}
                  <Divider />
                  <Col md={12}>
                     <h3>Sembunyikan workspace?</h3>
                     <Row>
                        <Checkbox
                           onChange={(e) => setHideWorkspace(e.target.checked)}
                           checked={Boolean(hideWorkspace)}
                        >
                           Sembunyikan
                        </Checkbox>
                     </Row>
                  </Col>
               </Col>
            </Row>
         </Modal>
      </>
   );
};

export default PharmacyForm;
