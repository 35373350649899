/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Layout, Form, Input, Button, Spin, Select, notification } from "antd";

// component
import Sidebar from "../../component/Sidebar";
import getAnalytics from "./utils/analyticsAPI";
import { Pharmacy } from "../../types/pharmacy";
import getPharmacyList from "../Pharmacy/utils/getPharmacyList";

const AnalyticsComponent = () => {
  const { Header, Content, Sider } = Layout;
  const { Option } = Select;

  const [form] = Form.useForm();

  const [MA, setMA] = useState(1);
  const [period, setPeriod] = useState(1);
  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pharmacyLoading, setPharmacyLoading] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState<null | number>(null);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (!selectedPharmacy) {
        notification.error({ message: "Pilih apotek dulu" });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _data = await getAnalytics({
        ma: MA,
        period,
        company_id: selectedPharmacy!,
      });
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const getPharmacyListAsync = async () => {
    try {
      setPharmacyLoading(true);

      const data: Pharmacy[] = await getPharmacyList();
      setPharmacies(data);
    } catch (err) {
    } finally {
      setPharmacyLoading(false);
    }
  };

  const getPharmacyOptions = useMemo(() => {
    return pharmacies.map((p) => {
      return <Option value={p.id}> {p.name}</Option>;
    });
  }, [pharmacies]);

  useEffect(() => {
    getPharmacyListAsync();
  }, []);

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Sider breakpoint="lg" collapsedWidth="0">
          <Sidebar activeTab={"analytics"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>Analytics</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            {isLoading ? (
              <Spin size="large" />
            ) : (
              <Form form={form} layout="vertical" style={{ width: "300px" }}>
                <Form.Item label="Moving Average">
                  <Input
                    placeholder=""
                    value={MA}
                    onChange={(e) => setMA(+e.target.value)}
                    addonAfter="hari"
                  />
                </Form.Item>
                <Form.Item label="Periode">
                  <Input
                    value={period}
                    placeholder=""
                    onChange={(e) => setPeriod(+e.target.value)}
                    addonAfter="hari"
                  />
                </Form.Item>
                <Form.Item>
                  <Select
                    showSearch
                    loading={pharmacyLoading}
                    style={{ width: 200 }}
                    placeholder="Pilih apotek"
                    optionFilterProp="children"
                    onSelect={(val) => setSelectedPharmacy(val as any)}
                  >
                    {getPharmacyOptions}
                  </Select>
                  ,
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={onSubmit}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AnalyticsComponent;
