/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";

// utils
import { getPriceHistoryDetail } from "./utils/getPriceHistory";

// type
import { PriceHistory, PriceHistoryDetail } from "../../types/priceHistory";
import { StockActionType } from "../../types/general";
import moment from "moment";

type Props = {
  product_id: number;
  product_name?: string;
};

const PriceHistoryDetailComponent = (props: Props) => {
  const { product_id } = props;

  const [list, setList] = useState<PriceHistory[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 50,
  });

  const handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    try {
      setIsLoading(true);
      const listMonitorLambda = await getPriceHistoryDetail(product_id, {
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...pagination,
        ...filters,
      });
      setList(listMonitorLambda.collections);
      setPagination({
        ...pagination,
        total: listMonitorLambda.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const renderActionType = (at: string | null, val: PriceHistory) => {
    switch (String(at)) {
      case StockActionType.ANULL:
        return <>Data per tanggal {moment(val.created_at).format("DD/MM/YYYY")}</>;
      case StockActionType.A000:
        return <>Stok Awal</>;
      case StockActionType.A111:
        return <>Penjualan Kasir</>;
      case StockActionType.A131:
      case StockActionType.A132:
        return <>Retur penjualan kasir</>;
      case StockActionType.A211:
        return <>Penerimaan faktur {val.source_number}</>;
      case StockActionType.A221:
      case StockActionType.A222:
        return <>Edit faktur {val.source_number}</>;
      case StockActionType.A232:
        return <>Hapus faktur {val.source_number}</>;
      case StockActionType.A311:
      case StockActionType.A312:
        return <>Update Stok</>;
      case StockActionType.A411:
      case StockActionType.A412:
        return <>Stok Opname</>;
      case StockActionType.A911:
        return <>Input HPS di kasir</>;
      case StockActionType.A912:
        return <>Perubahan HPS karena di-override</>;
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "crated_at",
    },
    {
      title: "Keterangan",
      dataIndex: "action_type",
      key: "action_type",
      render(action_type: string | null, row: PriceHistory) {
        return renderActionType(action_type, row);
      },
    },
    {
      title: "Harga",
      dataIndex: "total_price_buy",
      key: "total_price_buy",
    },
    {
      title: "Qty",
      dataIndex: "qty_balance",
      key: "qty_balance",
      render(_: number, row: PriceHistory) {
        let qty = 0;
        row.details.forEach((value: PriceHistoryDetail) => {
          qty += value.qty_change;
        });
        return qty >= 0 ? `+${qty}` : `${qty}`;
      },
    },
    {
      title: "Final HPS",
      dataIndex: "final_HPS",
      key: "final_HPS",
    },
    {
      title: "Final stok",
      dataIndex: "qty_balance",
      key: "qty_balance",
    },
  ];

  const getMonitorLambda = async () => {
    try {
      setIsLoading(true);
      const listPriceHistoryDetail = await getPriceHistoryDetail(product_id, {
        ...pagination,
      });

      setList(listPriceHistoryDetail.collections);
      setPagination({
        ...pagination,
        total: listPriceHistoryDetail.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const getFormattedList = useMemo(() => {
    const result: any[] = [];
    list.forEach((p: PriceHistory, i: number) => {
      let tempPricePerUnit = p.price_per_unit;

      const defaulQtyValue = p.action_type === StockActionType.A911 && p.details.length === 1 ? 1 : 0;

      let qty = 0;
      let price = 0;
      let totalPriceBuy = 0;
      p.details.forEach((value: PriceHistoryDetail) => {
        price += (value.qty_change || defaulQtyValue) * (value.batch_cogs || 0);
        qty += value.qty_change || 0;
      });

      totalPriceBuy = price / (qty || 1);

      if (tempPricePerUnit === null && (p.action_type === StockActionType.A221 || p.action_type === StockActionType.A222)) {
        tempPricePerUnit = price / (-qty || 1) + list[i + 1]?.price_per_unit;
        if (p.qty_balance !== 0) {
          tempPricePerUnit = (price / (-qty || 1) + list[i + 1]?.price_per_unit) / (p.qty_balance || 1);
        }
      }

      result.push({
        id: p.id,
        harga_setelah: list[i + 1]?.price_per_unit || 0,
        created_at: p.created_at,
        product_id: p.product_id,
        product_name: p.product_name,
        final_HPS: tempPricePerUnit,
        total_price_buy: totalPriceBuy,
        price_per_unit_before: p.price_per_unit_before,
        stock_balance: p.stock_balance,
        description: p.description,
        action_type: p.action_type,
        source_id: p.source_id,
        source_number: p.source_number,
        qty_balance: p.qty_balance,
        details: p.details,
      });
    });

    return result;
  }, [list]);

  const expandedRowRender = (record: PriceHistory) => {
    const columns = [
      {
        title: "Batch",
        dataIndex: "batch_no",
        key: "batch_no",
        render: (val: string | null) => {
          return val !== null ? val : "[tanpa-nomor]";
        },
      },
      { title: "Harga pokok", dataIndex: "batch_cogs", key: "batch_cogs" },
      {
        title: "Qty",
        dataIndex: "qty_change",
        key: "qty_change",
        render: (val: number) => {
          return val >= 0 ? `+${val}` : `${val}`;
        },
      },
      { title: "Harga Beli", dataIndex: "price_buy", key: "price_buy" },
      {
        title: "PPN",
        dataIndex: "vat_amount",
        key: "vat_amount",
        render: (_: number, row: PriceHistoryDetail) => {
          return `${row.vat_amount} (${row.vat_percentage_invoice || 0}%)`;
        },
      },
      {
        title: "Diskon barang",
        dataIndex: "discount_type_product",
        key: "discount_type_product",
        render: (_: string, row: PriceHistoryDetail) => {
          if (row.discount_type_product === "percentage") {
            return `${row.discount_amount_product} %`;
          }
          return `Rp. ${row.discount_amount_product}`;
        },
      },
      {
        title: "Diskon invoice",
        dataIndex: "discount_type_invoice",
        key: "discount_type_invoice",
        render: (_: string, row: PriceHistoryDetail) => {
          if (row.discount_type_invoice === "percentage") {
            return `${row.discount_amount_invoice} % `;
          }
          return `Rp. ${row.discount_amount_invoice}`;
        },
      },
    ];

    return <Table columns={columns} dataSource={record.details} pagination={false} />;
  };

  useEffect(() => {
    getMonitorLambda();
  }, [product_id]);

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        dataSource={getFormattedList}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
        expandable={{ expandedRowRender }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default PriceHistoryDetailComponent;
