import { Button, Table } from "antd";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Sidebar from "../../../component/Sidebar";
import { ProductTaxHistory } from "../../../service/product/interface";
import { nominalFormat } from "../../../utils/nominalFormat";
import { useTaxHistoryReportDetail } from "./hooks/useTaxHistoryReportDetail";

export const TaxReportHistoryDetail = () => {
   const { list, productName, filter, totalData, isFetching, onBack } = useTaxHistoryReportDetail();

   const typeLabel = { addition: "Penambahan", reduction: "Pengurangan" };
   const activityLabel: any = {
      sales: "Penjualan",
      refund: "Retur",
      stock_taking: "Update stok",
      transfer_stock: "Transfer stok",
      update_stock: "Update stok",
      add_invoice: "Tambah faktur",
      edit_invoice: "Edit faktur",
      delete_invoice: "Hapus faktur",
      add_item: "Tambah barang",
      update_price: "Edit harga",
   };

   const columns = [
      {
         title: "Aktivitas",
         dataIndex: "activity",
         key: "activity",
         render: (_: any, p: ProductTaxHistory) => {
            return activityLabel[p.activity];
         },
      },
      {
         title: "Tipe aktivitas",
         dataIndex: "activity_type",
         key: "activity_type",
         render: (_: any, p: ProductTaxHistory) => {
            return typeLabel[p.activity_type];
         },
      },
      {
         title: "Qty",
         dataIndex: "qty",
         key: "qty",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.qty);
         },
      },
      {
         title: "Stok saat ini",
         dataIndex: "total_stock",
         key: "total_stock",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.total_stock);
         },
      },
      {
         title: "Harga beli",
         dataIndex: "price",
         key: "price",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.price, 2);
         },
      },
      {
         title: "PPN",
         dataIndex: "tax",
         key: "tax",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.tax, 2);
         },
      },
      {
         title: "Biaya meterai",
         dataIndex: "stamp_duty",
         key: "stamp_duty",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.stamp_duty, 2);
         },
      },
      {
         title: "Biaya pengiriman",
         dataIndex: "shipping",
         key: "shipping",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.shipping, 2);
         },
      },
      {
         title: "PPN di stok",
         dataIndex: "tax_in_stock",
         key: "tax_in_stock",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.tax_in_stock, 2);
         },
      },
      {
         title: "Materai di stok",
         dataIndex: "stamp_duty_in_stock",
         key: "stamp_duty_in_stock",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.stamp_duty_in_stock, 2);
         },
      },
      {
         title: "Biaya kirim di stok",
         dataIndex: "shipping_in_stock",
         key: "shipping_in_stock",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.shipping_in_stock, 2);
         },
      },
      {
         title: "Nilai stok",
         dataIndex: "stock_value",
         key: "stock_value",
         render: (_: any, p: ProductTaxHistory) => {
            return nominalFormat(p.stock_value, 2);
         },
      },
   ];

   return (
      <Layout style={{ height: "100vh" }}>
         <Sider breakpoint='lg' collapsedWidth='0'>
            <Sidebar activeTab={"tax-report-history"} />
         </Sider>
         <Layout>
            <Header style={{ background: "#fff" }}>
               <Button onClick={onBack}>Kembali</Button>{" "}
               <h1>{productName || list[0]?.product_name || ""}</h1>
            </Header>
            <Content
               style={{
                  padding: "24px",
                  paddingBottom: "100px",
                  overflowY: "scroll",
               }}
            >
               <Table
                  scroll={{ y: "calc(100vh - 220px)" }}
                  rowKey={(record) => record.product_id}
                  dataSource={list}
                  columns={columns}
                  pagination={{
                     current: filter.p,
                     pageSize: filter.rp,
                     total: totalData,
                  }}
                  loading={isFetching}
               />
            </Content>
         </Layout>
      </Layout>
   );
};
