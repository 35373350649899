import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

export const getRegionList = async () => {
  try {
    const url = `${BASE_URL_BETA}/v2/region`;
    const response = await axios.get(url);

    if (response && response.status === 200) {
      return response.data.regions || [];
    }
  } catch (error) {
    notification.error({
      message: "Gagal memuat data region",
      placement: "topRight",
      duration: 2,
    });
  }
};
