import { notification } from "antd";
import { AxiosResponse } from "axios";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

export interface DataCreateChain {
  main_company_id: string;
  company_group: string;
  company_group_id?: string;
  branch_name: string;
  province_id: string;
  city_id: string;
}

export const addOrCreateChainGroups = async (data: DataCreateChain): Promise<AxiosResponse<any>> => {
  const url = `${BASE_URL_BETA}/admin/company/chain`;
  return axios.post(url, data);
};

export const getDetailChain = async (company_id: string) => {
  try {
    const url = `${BASE_URL_BETA}/admin/company/chain/${company_id}`;
    const response = await axios.get(url);

    if (response && response.status < 205) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: `Gagal memuat data!`,
      placement: "topRight",
      duration: 2,
    });
  }
};
