import { Redirect, Route } from "react-router-dom";

export const RedirectRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <Redirect
          to={{ pathname: "/company/pharmacy", state: { from: props.location } }}
        />
      );
    }}
  />
);
