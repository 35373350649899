import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const getPharmacyList = async (params: any = {}) => {
  try {
    const url = `${BASE_URL_BETA}/admin/company`;
    const response = await axios.get(url, {
      params: params,
    });

    if (response && response.status === 200) {
      return response.data.company;
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
  }
};

export default getPharmacyList;
