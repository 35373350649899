import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const putSubcriptionPackage = async (data: {
  id: any;
  title: string;
  description: string;
  monthlyFee: number;
  activePeriod: number;
  isActive: string;
  discountType: string;
  discountAmount: number;
  freeTrialDays: number;
  showInApp: boolean;
}) => {
  const {
    id,
    title,
    description,
    monthlyFee,
    activePeriod,
    isActive,
    discountType,
    discountAmount,
    freeTrialDays,
    showInApp,
  } = data;
  try {
    const url = `${BASE_URL_BETA}/admin/subscription/${id}`;

    const payload = {
      id: id,
      title: title,
      description: description,
      monthly_fee: monthlyFee,
      active_period: activePeriod,
      is_active: isActive === "active" ? true : false,
      discount_type: discountType,
      discount_amount: discountAmount,
      free_trial_days: freeTrialDays,
      show_in_app: showInApp,
    };

    const response = await axios.put(url, payload);

    if (response && response.status === 201) {
      notification.success({
        message: "Berhasil edit subscription package",
      });
      return response.data;
    }
  } catch (err) {
    notification.error({
      message: "Gagal memperbaharui",
      placement: "topRight",
      duration: 2,
    });
    throw new Error(err);
  }
};

export default putSubcriptionPackage;
