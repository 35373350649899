import React from "react";
import { useHistory } from "react-router-dom";
import { Layout, Menu } from "antd";

export type Props = {
   activeTab:
      | "logger"
      | "pharmacy"
      | "logger"
      | "export-data"
      | "price-history"
      | "user"
      | "data-anomaly"
      | "monitor-system-resources"
      | "analytics"
      | "pharmacies-conversion"
      | "tnc"
      | "fc-catalog"
      | "subscription-package"
      | "paid-subscription"
      | "access-control"
      | "promotion"
      | "tax-report-history";
};

const Sidebar = (props: Props) => {
   const { Sider } = Layout;
   const { activeTab } = props;

   const history = useHistory();

   const handleLogout = () => {
      localStorage.clear();
      history.push("/sign-in");
   };

   const handleNavigate = (path: string) => {
      history.push(path);
   };

   const userRole = localStorage.getItem("userRole");

   const getTheme = () => {
      const env = process.env.REACT_APP_ENV;
      if (env === "production") {
         return "light";
      }

      if (env === "test") {
         return "light";
      }

      return "dark";
   };

   const getBackground = () => {
      const env = process.env.REACT_APP_ENV;
      if (env === "production") {
         return "#9FCACF";
      }

      if (env === "test") {
         return "#FF6C47";
      }

      return undefined;
   };

   return (
      <Sider breakpoint='lg' collapsedWidth='0'>
         <Menu
            mode='inline'
            defaultSelectedKeys={[activeTab]}
            theme={getTheme()}
            style={{
               background: getBackground(),
            }}
         >
            {["admin", "sales", "canvasser"].includes(String(userRole)) ? (
               <Menu.Item
                  key='company/pharmacy'
                  onClick={() => handleNavigate("/company/pharmacy")}
               >
                  List Apotek
               </Menu.Item>
            ) : null}
            {["admin", "sales", "canvasser"].includes(String(userRole)) ? (
               <Menu.Item
                  key='company/distributor'
                  onClick={() => handleNavigate("/company/distributor")}
               >
                  List PBF
               </Menu.Item>
            ) : null}{" "}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item key='export-data' onClick={() => handleNavigate("/export-data")}>
                  Export Data
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item key='price-history' onClick={() => handleNavigate("/price-history")}>
                  Price History
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item
                  key='tax-report-history'
                  onClick={() => handleNavigate("/tax-report-history")}
               >
                  Tax Report History
               </Menu.Item>
            ) : null}
            {["admin"].includes(String(userRole)) ? (
               <Menu.Item key='logger' onClick={() => handleNavigate("/logger")}>
                  Log
               </Menu.Item>
            ) : null}
            {["admin"].includes(String(userRole)) ? (
               <Menu.Item key='user' onClick={() => handleNavigate("/user")}>
                  User
               </Menu.Item>
            ) : null}
            {["admin"].includes(String(userRole)) ? (
               <Menu.Item key='data-anomaly' onClick={() => handleNavigate("/data-anomaly")}>
                  Data Anomali
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item
                  key='monitor-system-resources'
                  onClick={() => handleNavigate("/monitor-system-resources")}
               >
                  Monitor System
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item key='analytics' onClick={() => handleNavigate("/analytics")}>
                  Analytics
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item
                  key='pharmacies-conversion'
                  onClick={() => handleNavigate("/pharmacies-conversion")}
               >
                  Pharmacies Conversion
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item key='tnc' onClick={() => handleNavigate("/tnc")}>
                  Link management
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item key='fc-catalog' onClick={() => handleNavigate("/fc-catalog")}>
                  Farmacare Catalog
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item
                  key='subscription-package'
                  onClick={() => handleNavigate("/subscription-package")}
               >
                  Subscription Package
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item key='promotion' onClick={() => handleNavigate("/promotion")}>
                  Promo
               </Menu.Item>
            ) : null}
            {["admin", "sales"].includes(String(userRole)) ? (
               <Menu.Item
                  key='paid-subscription'
                  onClick={() => handleNavigate("/paid-subscription")}
               >
                  Paid Subscription
               </Menu.Item>
            ) : null}
            {["admin"].includes(String(userRole)) ? (
               <Menu.Item key='access-control' onClick={() => handleNavigate("/access-control")}>
                  Access Control
               </Menu.Item>
            ) : null}
            <Menu.Item key='logout' onClick={handleLogout}>
               Logout
            </Menu.Item>
         </Menu>
      </Sider>
   );
};

export default Sidebar;
