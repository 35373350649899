/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Row, Spin, Empty, Select, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../../component/Modal/Confirmation";
import { deleteDataFeatureFlag, getDataFeatureFlag, postDataFeatureFlag, putDataFeatureFlag } from "./utils/network";

const { Option } = Select;

const styleHeader: React.CSSProperties = {
  padding: "8px",
  background: "#c6cdd7",
  height: "100%",
};
const style: React.CSSProperties = {
  padding: "8px",
  background: "#f5f5f5",
  height: "100%",
};
const style2: React.CSSProperties = {
  padding: "8px",
  background: "#ffffff",
  height: "100%",
};
const addNewStyle: React.CSSProperties = {
  padding: "8px",
  background: "#fffca7",
  height: "100%",
};

type GridAddTableData = {
  header: HeaderTableFormat[];
  useCRUD?: UseCRUDType;
  setAdd: (e: boolean) => void;
  endPoint: string;
  setReload: (e: boolean) => void;
  add: boolean;
  disabled: boolean;
  setDisabled: (e: boolean) => void;
};

type GridTableContentsType = {
  indexRow: number;
  header: HeaderTableFormat[];
  element: any;
  useCRUD?: UseCRUDType;
  setReload: (e: boolean) => void;
  endPoint: string;
  setDisabled: (e: boolean) => void;
  disabled: boolean;
  featureOptions: any;
  featureOptionsAsMap: any;
};

export type HeaderTableFormat = {
  name: string;
  span: number;
  keys: string;
  custom?: (e: any) => any;
  noEdit?: boolean;
};

type GridTablesType = {
  header: HeaderTableFormat[];
  useCRUD?: UseCRUDType;
  endPoint?: string;
  datas?: any;
  searchKey: string[];
  titleButton?: { add?: string };
};

type UseCRUDType = {
  initialForm: any;
  span: number;
  api: string;
  edit: boolean;
  add: boolean;
  delete: boolean;
};

const GridTables = (props: GridTablesType) => {
  const { header, useCRUD, endPoint = "", searchKey = [], datas, titleButton } = props;
  const [add, setAdd] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [featureList, setFeatureList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [search, setSearch] = useState("");
  const history = useHistory();
  const fetchDataFeatureFlagList = async () => {
    setLoading(true);
    setReload(false);
    if (datas) {
      setFeatureList(datas);
    } else {
      await getDataFeatureFlag({ endPoint: endPoint ? endPoint : "" }).then((res) => {
        setDisabled(false);
        const dataCollection = res?.collection ? res.collection : [];
        setFeatureList(dataCollection);
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (reload) {
      fetchDataFeatureFlagList();
    }
  }, [reload, history.location]);

  const filterData = featureList.filter((item: any) => {
    const keyWords = searchKey.reduce((collectData: string, newData: string) => {
      return `${collectData}${item?.[newData]?.toString()?.toLowerCase()}`;
    }, "");
    return keyWords.includes(search.toLowerCase());
  });

  const getFeatureOptions = useMemo(() => {
    return (featureList || []).map((f: any, index: number) => {
      return { value: f.id.toString(), label: f.feature_key };
    });
  }, [featureList]);

  const getFeatureOptionsAsMap = useMemo(() => {
    let features = new Map();
    (featureList || []).forEach((f: any, index: number) => {
      features.set(f.id.toString(), f.feature_key);
    });

    return features;
  }, [featureList]);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div>
          <Input
            onChange={(e: any) => {
              setDisabled(false);
              setSearch(e.target.value);
            }}
            placeholder='search...'
          />
        </div>
        {useCRUD?.add && (
          <AddButton title={titleButton?.add} add={add} setAdd={setAdd} setDisabled={setDisabled} disabled={disabled} />
        )}
      </div>
      <GridTableHeader header={header} useCRUD={useCRUD} />
      {loading && <Loading />}
      {add && (
        <GridTableAddData
          setReload={(e) => setReload(e)}
          add={add}
          disabled={disabled}
          setDisabled={setDisabled}
          header={header}
          useCRUD={useCRUD}
          setAdd={setAdd}
          endPoint={endPoint}
        />
      )}
      {filterData?.length === 0 ? (
        <div style={{ padding: "10px" }}>
          <Empty />
        </div>
      ) : (
        filterData.map((element: any, idx: number) => (
          <GridTablesContents
            setReload={setReload}
            endPoint={endPoint}
            setDisabled={setDisabled}
            disabled={disabled}
            key={idx}
            featureOptions={getFeatureOptions}
            featureOptionsAsMap={getFeatureOptionsAsMap}
            indexRow={idx}
            useCRUD={useCRUD}
            element={element}
            header={header}
          />
        ))
      )}
    </React.Fragment>
  );
};

export default GridTables;

const AddButton = (props: {
  title?: string;
  add: boolean;
  setAdd: (e: boolean) => void;
  disabled: boolean;
  setDisabled: (e: boolean) => void;
}) => {
  const { add, setAdd, setDisabled, disabled, title } = props;
  if (add) {
    return null;
  }
  return (
    <Button
      disabled={!add && disabled}
      type={"primary"}
      onClick={() => {
        setDisabled(true);
        setAdd(!add);
      }}
    >
      {title ? title : "Add new feature"}
    </Button>
  );
};

const GridTableHeader = (props: { header: HeaderTableFormat[]; useCRUD?: UseCRUDType }) => {
  const { header, useCRUD } = props;
  return (
    <Row gutter={[3, 3]} style={{}}>
      {header.map((item, index) => (
        <Col span={item.span} key={index}>
          <div style={styleHeader}>{item.name}</div>
        </Col>
      ))}
      {(useCRUD?.edit || useCRUD?.delete) && (
        <Col span={useCRUD.span}>
          <div style={styleHeader}>Action</div>
        </Col>
      )}
    </Row>
  );
};

const GridTableAddData = (props: GridAddTableData) => {
  const { header, useCRUD, setAdd, endPoint, setReload, add, setDisabled } = props;
  const [form, setForm] = useState(useCRUD?.initialForm);
  const handleForm = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const [loading, setLoading] = useState(false);

  const handleSaveForm = async () => {
    setLoading(true);
    await postDataFeatureFlag({ endPoint: endPoint, data: form }).then((res) => {
      if (res) {
        setAdd(false);
        setReload(true);
        setForm(useCRUD?.initialForm);
      }
    });
    setLoading(false);
  };

  const handleCancel = () => {
    setAdd(false);
    setDisabled(false);
    setForm(useCRUD?.initialForm);
  };

  return (
    <Row gutter={[3, 3]} style={{}}>
      {header.map((item, index) => (
        <Col span={item.span} key={index}>
          <div style={addNewStyle}>
            {!!item?.custom ? (
              item?.custom({ setForm, form, isEditOrAdd: add })
            ) : (
              <Input onChange={handleForm} name={item.keys} />
            )}
          </div>
        </Col>
      ))}
      {(useCRUD?.edit || useCRUD?.delete) && (
        <Col span={useCRUD?.span}>
          <div style={addNewStyle}>
            <Row gutter={4}>
              {useCRUD?.edit && (
                <Col span={12}>
                  <Button loading={loading} style={{ width: "100%" }} type={"primary"} onClick={handleSaveForm}>
                    Save
                  </Button>
                </Col>
              )}
              {useCRUD?.delete && (
                <Col span={12}>
                  <Button style={{ width: "100%" }} danger onClick={handleCancel}>
                    Cancel
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      )}
    </Row>
  );
};

const GridTablesContents = (props: GridTableContentsType) => {
  const { header, element, indexRow, useCRUD, setReload, endPoint, featureOptions, featureOptionsAsMap, setDisabled, disabled } =
    props;
  const isGray = indexRow % 2 === 0;
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<any>({});

  const handleForm = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSaveForm = async () => {
    setLoading(true);
    await putDataFeatureFlag({
      endPoint: `${endPoint}/${element.id}`,
      data: form,
    }).then((res) => {
      if (res !== undefined) {
        setEdit(false);
        setReload(true);
        setForm(useCRUD?.initialForm);
      }
    });
    setLoading(false);
  };

  const handleCancel = () => {
    setEdit(false);
    setDisabled(false);
    setForm(useCRUD?.initialForm);
  };

  const showEdit = (e: any) => {
    const initialForm = useCRUD?.initialForm
      ? Object.keys(useCRUD?.initialForm)?.reduce((next: Object, other: string) => {
          if (typeof element?.[other] === "object") {
            return next;
          } else {
            return { ...next, [other]: element?.[other] };
          }
        }, {})
      : {};
    setDisabled(true);
    setForm({ ...initialForm, prerequisite: e.prerequisite });
    setEdit(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    await deleteDataFeatureFlag({ endPoint: `${endPoint}/${element.id}` }).then((res) => {
      if (res !== undefined) {
        setEdit(false);
        setReload(true);
        setForm(useCRUD?.initialForm);
      }
    });
    setLoading(false);
  };

  return (
    <Row gutter={[3, 3]} style={{}}>
      {header.map((item, index) => (
        <Col span={item.span} key={index}>
          <div style={edit ? addNewStyle : isGray ? style : style2}>
            {item?.custom ? (
              item?.custom({
                ...element,
                setForm: (e: any) => setForm(e),
                form,
                isEditOrAdd: edit,
                setReload: setReload,
                featureOptions,
                featureOptionsAsMap,
              })
            ) : edit && !item.noEdit ? (
              <Input onChange={handleForm} value={form?.[item?.keys]} name={item?.keys} />
            ) : element?.[item?.keys] ? (
              element?.[item?.keys]
            ) : (
              "-"
            )}
          </div>
        </Col>
      ))}
      {(useCRUD?.edit || useCRUD?.delete) && (
        <Col span={useCRUD?.span} key={indexRow}>
          {edit ? (
            <div style={addNewStyle}>
              <Row gutter={4}>
                <Col span={12}>
                  <Button loading={loading} style={{ width: "100%" }} type={"primary"} onClick={handleSaveForm}>
                    Save
                  </Button>
                </Col>
                <Col span={12}>
                  <Button style={{ width: "100%" }} danger onClick={handleCancel}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <div style={isGray ? style : style2}>
              <Row gutter={4}>
                {useCRUD?.edit && (
                  <Col span={useCRUD?.delete ? 12 : 24}>
                    <Button
                      disabled={disabled && !edit}
                      style={{ width: "100%" }}
                      type={"primary"}
                      onClick={() => showEdit(element)}
                    >
                      Edit
                    </Button>
                  </Col>
                )}
                {useCRUD?.delete && (
                  <Col span={useCRUD?.edit ? 12 : 24}>
                    <ConfirmationModal
                      button={{ danger: true, title: "Delete" }}
                      states={{
                        content: `Apakah kamu yakin?`,
                        onOk: () => handleDelete(),
                      }}
                    />
                  </Col>
                )}
              </Row>
            </div>
          )}
        </Col>
      )}
    </Row>
  );
};

const Loading = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
      <Spin size='large' />
    </div>
  );
};

type TagListType = {
  e: any;
  option: any;
  list: {
    name: string;
    id: number;
  }[];
  noSelectedValue?: string;
  defaultSelected?: number[];
  setNewFormData?: () => void;
  loading?: boolean;
  formValue: number[];
  setFormValue: (e: any) => void;
};

export const TagList = (props: TagListType) => {
  const {
    e,
    option = [],
    list,
    defaultSelected = [],
    setNewFormData,
    loading = false,
    formValue = [],
    setFormValue,
    noSelectedValue,
  } = props;

  useEffect(() => {
    if (setNewFormData && e?.isEditOrAdd) {
      setNewFormData();
    }
  }, [e?.isEditOrAdd]);

  if (e?.isEditOrAdd && option) {
    return (
      <Select
        mode='multiple'
        allowClear
        loading={loading}
        style={{ width: "100%" }}
        placeholder='Please select'
        value={!!noSelectedValue && formValue?.length === 0 ? [""] : formValue}
        defaultValue={defaultSelected}
        filterOption={(input, option) => (option?.children || "").toString()?.toLowerCase().includes(input?.toLowerCase())}
        onChange={setFormValue}
      >
        {!!noSelectedValue && (
          <Option value={""} key={"no-data-selected"}>
            {noSelectedValue}
          </Option>
        )}
        {option?.map((el: any) => (
          <Option value={el?.id} key={el?.id}>
            {el.name}
            {el.city_name && `, ${el.city_name}`}
          </Option>
        ))}
      </Select>
    );
  } else {
    return (
      <div>
        {list?.length < 1 && !!noSelectedValue ? (
          <Tag color='geekblue' style={styles.tagout}>
            {noSelectedValue}
          </Tag>
        ) : (
          list?.map((item: any, index: number) => (
            <Tag key={index} color='geekblue' style={styles.tagout}>
              {item?.name}
            </Tag>
          ))
        )}
      </div>
    );
  }
};

const styles = {
  tagout: { paddingHorizontal: 0, borderRadius: "7px", margin: "5px" },
};
