import { Button, Col, Modal, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import PharmacyAddOnForm from "./PharmacyAddOnForm";
import { AddOn } from "./utils/interface";

type Props = {
  pharmacyId: string | number;
  addOnList: AddOn[];
  onCloseModal: () => void;
  refetch: () => void;
};

export const PharmacyAddOnList = ({
  addOnList,
  pharmacyId,
  onCloseModal,
  refetch,
}: Props) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedAddOn, setSelectedAddOn] = useState<null | AddOn>(null);

  const columns = [
    {
      title: "Add on",
      dataIndex: "addOn",
      key: "packageName",
      render: (_: any, row: AddOn) => {
        return (
          <span>
            <b>{row?.name || ""}</b>
            <br />
            {row?.description || ""}
          </span>
        );
      },
    },
    {
      title: "Aktif sampai",
      dataIndex: "activeUntil",
      key: "activeUntil",
      render: (_: any, row: AddOn) => {
        if (!row.active_until) {
          return "-";
        }

        return moment(row?.active_until).format("YYYY MMM DD");
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_: any, row: AddOn) => {
        return (
          <Button
            onClick={() => {
              setShowForm(true);
              setSelectedAddOn(row);
            }}
          >
            Ubah
          </Button>
        );
      },
    },
  ];

  const onCloseFormModal = () => {
    setSelectedAddOn(null);
    setShowForm(false);
  };

  return (
    <>
      {showForm && (
        <PharmacyAddOnForm
          pharmacyId={pharmacyId}
          selectedAddOn={selectedAddOn}
          selectedAddOonList={addOnList}
          onCloseModal={() => onCloseFormModal()}
          refetch={refetch}
        />
      )}

      <Modal
        visible={!showForm}
        title='Add on'
        onCancel={onCloseModal}
        footer={<Button onClick={() => onCloseModal()}>Tutup</Button>}
      >
        <Table columns={columns} dataSource={addOnList} pagination={false} />
        <Col style={{ marginTop: "24px" }}>
          <Button onClick={() => setShowForm(true)}>Tambah add on</Button>
        </Col>
      </Modal>
    </>
  );
};
