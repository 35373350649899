import React, { useEffect, useMemo, useState } from "react";
import { Layout, Table, Modal, Row, Col, DatePicker, Input } from "antd";

// utils
import {
  getMonitorSystemResourcesLog,
  getMonitorSystemResourcesList,
} from "./utils/getMonitorData";

// type
import { MonitorSystemResources, defaultMonitorSystemResources } from "../../types/monitorSystemResources";

// component
import Sidebar from "../../component/Sidebar";
import moment from "moment";
import { debounce } from "lodash";
import MonitorSystemResourcesLogComponent from "./MonitorSystemResourcesLog";


const MonitorSystemResourcesComponent = () => {
  const { Header, Content, Sider } = Layout;
  const { RangePicker } = DatePicker;

  const [list, setList] = useState<MonitorSystemResources[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] =
    useState<any>(/* {
    date_start: moment().format("YYYY-MM-DD 00:00:00"),
    date_end: moment().format("YYYY-MM-DD 23:59:59"),
  } */);
  const [selectedSystemResources, setSelectedSystemResources] =
    useState<MonitorSystemResources>(defaultMonitorSystemResources);
  const [modalVisible, setModalVisible] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 50,
  });

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    try {
      setIsLoading(true);
      const listMonitorSystemResources = await getMonitorSystemResourcesList({
        ...filter,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...pagination,
        ...filters,
      });
      setList(listMonitorSystemResources.collections);
      setPagination({
        ...pagination,
        total: listMonitorSystemResources.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "resource_type",
      key: "resource_type",
    },
    {
      title: "Name",
      dataIndex: "resource_name",
      key: "resource_name",
    },
    {
      title: "Status",
      dataIndex: "resource_status",
      key: "resource_status",
    },
    {
      title: "Last Status Change",
      dataIndex: "updated_at",
      key: "updated_at",
    },
  ];

  const getMonitorSystemResources = async () => {
    try {
      setIsLoading(true);
      const listMonitorSystemResources = await getMonitorSystemResourcesList({
        ...pagination,
        ...filter,
      });
      setList(listMonitorSystemResources.collections);
      setPagination({
        ...pagination,
        total: listMonitorSystemResources.total_data,
      });
    setIsLoading(false);
    } catch (err) {}
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case "lived":
        return "Live";
      case "downed":
        return "Down";

      default:
        return status;
    }
  };

  const renderDate = (
    date: string | null | undefined,
    includeHour?: boolean
  ) => {
    const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
    return date ? moment(date).format(format) : "-";
  };

  const getFormattedList = useMemo(() => {
    let result: MonitorSystemResources[] = list.map((p: MonitorSystemResources) => {
      return {
        id: p.id,
        resource_type: p.resource_type,
        resource_name: p.resource_name,
        resource_status: renderStatus(p.resource_status),
        created_at: p.created_at,
        updated_at:
          p.updated_at !== "None" ? renderDate(p.updated_at, true) : "Not yet",
      };
    });

    return result;
  }, [list, filter]);

  useEffect(() => {
    getMonitorSystemResources();
  }, [filter]);

  const onDateChange = debounce((values: any, dateString: [string, string]) => {
    setFilter({
      ...filter,
      date_start:
        values === null ? "" : values[0].format("YYYY-MM-DD 00:00:00"),
      date_end: values === null ? "" : values[1].format("YYYY-MM-DD 23:59:59"),
    });
  }, 800);

  const onDateOK = debounce((values: any) => {
    setFilter({
      ...filter,
      date_start:
        values === null ? "" : values[0].format("YYYY-MM-DD 00:00:00"),
      date_end: values === null ? "" : values[1].format("YYYY-MM-DD 23:59:59"),
    });
  }, 800);

  const onSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }, 800);

  const handleSelectSystemResources = async (record: any) => {
    setModalVisible(true);
    setSelectedSystemResources(record);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        visible={modalVisible}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        confirmLoading={isLoading}
        width={1000}
      >
        <MonitorSystemResourcesLogComponent system_resource_id={selectedSystemResources.id} />
      </Modal>

      <Layout style={{ height: "100vh" }}>
        <Sider breakpoint="lg" collapsedWidth="0">
          <Sidebar activeTab={"monitor-system-resources"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>Monitor System Resources</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Row style={{ marginBottom: "16px" }}>
              <Col span={8}>
                <Input
                  name="resource_name"
                  placeholder="Cari System Resource"
                  onChange={onSearch}
                />
              </Col>
            </Row>
            <Table
              rowKey={(record) => record.id}
              dataSource={getFormattedList}
              columns={columns}
              pagination={pagination}
              loading={isLoading}
              onChange={handleTableChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleSelectSystemResources(record);
                  }, // click row
                };
              }}
            />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default MonitorSystemResourcesComponent;
