export interface MonitorLambda {
  id: number;
  function_name: string;
  status: string;
  updated_at: string;
}

export interface MonitorLambdaLog {
  id: number;
  function_name: string;
  url: string;
  response_code: string;
  response_body: string;
  downed_at: string;
  lived_at: string;
}

export const defaultMonitorLambda: MonitorLambda = {
  id: 0,
  function_name: "",
  status: "",
  updated_at: ""
};


export const defaultMonitorLambdaLog: MonitorLambdaLog = {
  id: 0,
  function_name: "",
  url: "",
  response_code: "",
  response_body: "",
  downed_at: "",
  lived_at: ""
};
