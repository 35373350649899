import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { Product } from "../../types/priceHistory";
import { ProductTaxHistory } from "./interface";
import { ProductService } from "./productService";

export const useGetProductHistoryList = (
   enabled: boolean,
   params: { [key: string]: any },
   onError?: (err: any) => void
) => {
   const {
      data = { products: [], totalData: 0 },
      status,
      isFetching,
      error,
      isRefetching,
      refetch,
   } = useQuery(
      ["productHistoryList", params],
      async () => ProductService.getProductHistoryList(params),
      {
         select: (response: AxiosResponse) => {
            return {
               products: response.data?.collections || [],
               totalData: response.data?.total_data || 0,
            };
         },
         enabled,
         onError: (err: any) => onError && onError(err),
      }
   );

   return {
      list: data.products as Product[],
      totalData: data.totalData,
      error: error as any,
      status,
      isFetching,
      isRefetching,
      refetch,
   };
};

export const useGetProductTaxHistoryList = (
   enabled: boolean,
   productId: string,
   params: { [key: string]: any },
   onError?: (err: any) => void
) => {
   const {
      data = { collections: [], totalData: 0, productName: "" },
      status,
      isFetching,
      error,
      isRefetching,
      refetch,
   } = useQuery(
      ["productTaxHistoryList", productId, params],
      async () => ProductService.getTaxReportHistoryProduct(productId, params),
      {
         select: (response: AxiosResponse) => {
            return {
               productName: response.data.product_name,
               collections: response.data?.collections || [],
               totalData: response.data?.total_data || 0,
            };
         },
         enabled,
         onError: (err: any) => onError && onError(err),
      }
   );

   return {
      productName: data.productName,
      list: data.collections as ProductTaxHistory[],
      totalData: data.totalData,
      error: error as any,
      status,
      isFetching,
      isRefetching,
      refetch,
   };
};
