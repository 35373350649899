/* eslint-disable react-hooks/exhaustive-deps */
import { Layout } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Sidebar from "../../component/Sidebar";
import { Tabs } from 'antd';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import TabFeatureFlag from "./TabFeatureFlag";
import TabFeatureCompany from "./TabFeatureCompany";
const { TabPane } = Tabs;

const AccessControlDefaultPage = () => {
    const [menus, setMenus] = useState('');
    return (
        <Layout style={styles.layout}>
            <Sider breakpoint="lg" collapsedWidth="0">
                <Sidebar activeTab={"access-control"} />
            </Sider>
            <Layout>
                <Header style={styles.header}>
                    <div style={styles.flexTitle}>
                        <h1>Access control: </h1>
                        <p style={styles.subTitle}>{menus}</p>
                    </div>
                </Header>
                <Content
                    style={styles.contentContainer}
                >
                    <TabsContent setMenus={setMenus} />
                </Content>
            </Layout>
        </Layout>
    );
};

export default AccessControlDefaultPage;

const TabsContent = ({ setMenus }: { setMenus: (e: string) => void; }) => {
    const history = useHistory();
    const dataTabs = [
        {
            key: 'feature-flag',
            name: 'Feature flag',
            component: <TabFeatureFlag />
        },
        {
            key: 'company-groups',
            name: 'Company groups',
            component: <TabFeatureCompany />,
        }
    ];

    const onChnageTabs = (e: string) => {
        history.push(`/access-control/${e}`);
    };

    const activeTabs = dataTabs.filter((item) => history.location.pathname === `/access-control/${item.key}`)?.[0] || dataTabs?.[0];

    useEffect(() => {
        if (history.location.pathname === '/access-control') {
            history.replace(`/access-control/feature-flag`);
        }
    }, [history.location.pathname]);

    useEffect(() => {
        setMenus(activeTabs?.name);
    }, [activeTabs]);

    return (
        <div>
            <Tabs activeKey={activeTabs?.key} type="card" size={"middle"} onChange={onChnageTabs}>
                {dataTabs.map((item) => (
                    <TabPane tab={item.name} key={item.key}>
                        {item.component}
                    </TabPane>
                ))}
            </Tabs>
        </div >
    );
};



const styles: { [key: string]: React.CSSProperties; } = {
    contentContainer: {
        padding: "24px",
        paddingBottom: "100px",
        overflowY: "scroll",
    },
    header: { background: "#fff" },
    layout: { height: "100vh" },
    flexTitle: { display: 'flex', gap: "10px", flexDirection: 'row', flexWrap: 'wrap' },
    subTitle: { color: 'gray' }
};