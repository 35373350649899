import React, { useEffect, useMemo, useState } from "react";
import { Layout, Table, Modal, Row, Col, DatePicker, Input } from "antd";

// utils
import { getMonitorLambdaLog } from "./utils/getMonitorData";

// type
import { MonitorLambdaLog } from "../../types/monitorLambda";

// component
import Sidebar from "../../component/Sidebar";
import moment from "moment";
import { debounce } from "lodash";

type Props = {
  lambda_id: number;
};

const MonitorLambdaLogComponent = (props: Props) => {
  const { lambda_id } = props;
  const { Header, Content, Sider } = Layout;
  const { RangePicker } = DatePicker;

  const [list, setList] = useState<MonitorLambdaLog[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] =
    useState<any>(/* {
    date_start: moment().format("YYYY-MM-DD 00:00:00"),
    date_end: moment().format("YYYY-MM-DD 23:59:59"),
  } */);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 50,
  });

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    try {
      setIsLoading(true);
      const listMonitorLambda = await getMonitorLambdaLog(lambda_id, {
        ...filter,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...pagination,
        ...filters,
      });
      setList(listMonitorLambda.collections);
      setPagination({
        ...pagination,
        total: listMonitorLambda.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const columns = [
    {
      title: "Down at",
      dataIndex: "downed_at",
      key: "downed_at",
    },
    {
      title: "Lambda",
      dataIndex: "function_name",
      key: "function_name",
    },
    {
      title: "Live at",
      dataIndex: "lived_at",
      key: "lived_at",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Response",
      dataIndex: "response_code",
      render: (_: number, row: MonitorLambdaLog) => {
        return `${row.response_code} - ${row.response_body}`;
      },
    },
  ];

  const getMonitorLambda = async () => {
    try {
      setIsLoading(true);
      const listMonitorLambda = await getMonitorLambdaLog(lambda_id, {
        ...pagination,
        ...filter,
      });
      setList(listMonitorLambda.collections);
      setPagination({
        ...pagination,
        total: listMonitorLambda.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const renderDate = (
    date: string | null | undefined,
    includeHour?: boolean
  ) => {
    const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
    return date ? moment(date).format(format) : "-";
  };

  const getFormattedList = useMemo(() => {
    let result: MonitorLambdaLog[] = list.map((p: MonitorLambdaLog) => {
      return {
        id: p.id,
        function_name: p.function_name,
        url: p.url,
        response_code: p.response_code,
        response_body: p.response_body,
        downed_at: renderDate(p.downed_at, true),
        lived_at:
          p.lived_at !== "None" ? renderDate(p.lived_at, true) : "Not yet",
      };
    });

    return result;
  }, [list, filter]);

  useEffect(() => {
    getMonitorLambda();
  }, [lambda_id, filter]);

  const onDateChange = debounce((values: any, dateString: [string, string]) => {
    setFilter({
      ...filter,
      date_start:
        values === null ? "" : values[0].format("YYYY-MM-DD 00:00:00"),
      date_end: values === null ? "" : values[1].format("YYYY-MM-DD 23:59:59"),
    });
  }, 800);

  const onDateOK = debounce((values: any) => {
    setFilter({
      ...filter,
      date_start:
        values === null ? "" : values[0].format("YYYY-MM-DD 00:00:00"),
      date_end: values === null ? "" : values[1].format("YYYY-MM-DD 23:59:59"),
    });
  }, 800);

  // const onSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFilter({ ...filter, [e.target.name]: e.target.value });
  // }, 800);

  return (
    <>
      <Row style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <RangePicker
            // defaultValue={[moment(), moment()]}
            style={{ width: "100%" }}
            onChange={onDateChange}
            onOk={onDateOK}
          />
        </Col>
      </Row>
      <Table
        rowKey={(record) => record.id}
        dataSource={getFormattedList}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default MonitorLambdaLogComponent;
