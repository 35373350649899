import { notification } from "antd";
import axios from "../../../../config/axios";
import { BASE_URL_BETA } from "../../../../constant/api";

export const getActiveAddOns = async (pharmacyId: string | number | null) => {
  if (!pharmacyId) {
    return;
  }

  try {
    const url = `${BASE_URL_BETA}/admin/company/${pharmacyId}/addon`;
    const response = await axios.get(url);

    if (response.status === 200) {
      return response.data?.addon || [];
    }

    return [];
  } catch (err) {
    notification.error({ message: "Gagal memuat list add on" });
    return [];
  }
};
