/**
 *
 * @param nominal
 * format for number to easier to read
 */

export const nominalFormat = (
   nominal: string | number | undefined,
   decimalNumber?: number,
   currency?: string
) => {
   const absNominal = Math.abs(+nominal!);
   const fractionNumber = decimalNumber === 0 || decimalNumber ? decimalNumber : 0;
   const formattedNominal = new Intl.NumberFormat("id", {
      maximumFractionDigits: fractionNumber,
      minimumFractionDigits: fractionNumber,
   }).format(+(absNominal || 0).toFixed(fractionNumber));

   const prefix = +(nominal || "") < 0 ? "–" : "";

   if (currency) {
      return `${prefix}${currency}${formattedNominal}`;
   }

   return `${prefix}${formattedNominal}`;
};
