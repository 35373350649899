import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import React from 'react';


const confirm = (props: ConfirmType) => {
    Modal.confirm({ ...props });
};

type ConfirmType = {
    title?: string;
    icon?: any;
    content?: string;
    okText?: string;
    cancelText?: string;
    onOk: () => void;
};

type ButtonModalType = {
    danger?: boolean;
    size?: "small" | "large" | "middle";
    title?: string;
};

const ConfirmationModal = (props: { states?: ConfirmType; button: ButtonModalType; }) => {

    const { states, button } = props;
    const defaultState = {
        title: states?.title ? states?.title : 'Konfirmasi',
        icon: states?.icon ? states?.icon : <ExclamationCircleOutlined />,
        content: states?.content ? states?.content : 'Apakah kamu yakin?',
        okText: states?.okText ? states?.okText : 'OK',
        cancelText: states?.cancelText ? states?.cancelText : 'Cancel',
        onOk: states?.onOk ? () => states?.onOk() : () => { }
    };
    return (<Space>
        <Button {...button} type="primary" size={button?.size || 'middle'} onClick={() => confirm(defaultState)}>{button.title}</Button>
    </Space>);
};

export default ConfirmationModal;