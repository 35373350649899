import { useEffect, useState } from "react";
import {
  Layout,
  Row,
} from "antd";

// component
import Sidebar from "../../component/Sidebar";

import getDashboardUrl from "./utils/getDashboard";
import IframeResizer from "iframe-resizer-react";

const PaidSubscriptionComponent = () => {
  const { Header, Content, Sider } = Layout;
  const [dashboardUrl, setDashboardUrl] = useState<string | boolean>(false);

  const getDashboard = async () => {
    const response = await getDashboardUrl('paid_subscription');
    setDashboardUrl(response);
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Sider breakpoint="lg" collapsedWidth="0">
          <Sidebar activeTab={"paid-subscription"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>Paid Subscription</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Row >
              {
                dashboardUrl &&
                (

                  <IframeResizer
                    log
                    src={String(dashboardUrl)}
                    style={{ width: '1px', minWidth: '100%', 'border': '0px' }}
                  />)
              }
            </Row>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default PaidSubscriptionComponent;
