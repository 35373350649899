export interface Pharmacy {
   active_until: null | string;
   city_name: string;
   email: string;
   id: number;
   name: string;
   owner_name: string;
   phone_number: null | string;
   created_by: string;
   created_at: string;
   province_name: string;
   request_date: null | string;
   status: "active" | "inactive" | "inprogress";
   company_type: "pharmacy" | "distributor";
   is_internal: number;
   is_daily_active: number;
   is_paid: number;
   is_beta: number;
   is_hidden: boolean;
   referral: { name: string; phone_number: string; email: string };
   status_chain: string | null;
   group_name: string;
   company_group_id: string;
   utm_campaign: string;
   utm_source: string;
   utm_medium: string;
}

export interface PharmacyLog {
   id: number;
   type: string;
   value: string;
   created_at: string;
   user: string;
   title: string;
   reason: string;
}

export enum CompanyType {
   "pharmacy" = "pharmacy",
   "distributor" = "distributor",
}
