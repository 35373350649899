import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
} from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

// component
import Sidebar from "../../component/Sidebar";

import getExportData from "./utils/getExportData";
import getPharmacyList from "./utils/getPharmacyList";
import getListData from "./utils/getStockOpnameList";
import getCompanyUser from "./utils/getCompanyUser";
import { CompanyUser } from "../../types/user";

const ExportDataComponent = () => {
  const { Header, Content, Sider } = Layout;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [companyCollection, setCompanyCollection] = useState([
    { id: 0, name: "", city_name: "", province_name: "" },
  ]);
  const [companyList, setCompanyList] = useState([
    { id: 0, name: "", city_name: "", province_name: "" },
  ]);
  const [stockOpnameCollection, setStockOpnameCollection] = useState([
    { id: 0, checked_sum: 0, total_product: 0, end_at: "", is_finished: true },
  ]);
  const [stockOpanmeList, setStockOpnameList] = useState([
    { id: 0, checked_sum: 0, total_product: 0, end_at: "", is_finished: true },
  ]);
  const [selectedStockOpname, setSelectedStockOpname] = useState(0);
  const [stockOpnameInfo, setStockOpnameInfo] = useState("");

  const [companyUserCollection, setCompanyUserCollection] = useState<
    CompanyUser[]
  >([
    {
      id: 0,
      name: "",
      email: "",
      is_matamata: 0,
      status: "employee",
      username: "",
    },
  ]);
  const [companyUserList, setCompanyUserList] = useState<CompanyUser[]>([
    {
      id: 0,
      name: "",
      email: "",
      is_matamata: 0,
      status: "employee",
      username: "",
    },
  ]);
  const [selectedCompanyUser, setSelectedCompanyUser] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState({
    date_start: moment().format("YYYY-MM-DD"),
    date_end: moment().format("YYYY-MM-DD"),
  });

  const handleExportData = async (
    exportType: "stock" | "sales_history" | "stock_opname_report"
  ) => {
    try {
      setIsLoading(true);
      const queryParams = {
        exportType,
        companyID: selectedCompany,
        companyName: selectedCompanyName,
        dateStart: date.date_start,
        dateEnd: date.date_end,
        stockOpnameID: selectedStockOpname,
        userID: selectedCompanyUser,
        stockOpnameInfo: stockOpnameInfo,
      };
      await getExportData(queryParams);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onSelectCompanyChange = (value: number, option: any) => {
    setSelectedCompanyName(option?.children);
    setSelectedCompany(value);
  };

  const onSearchCompany = (value: string) => {
    const searchCompany = companyCollection.filter((v, i) => {
      const reg = new RegExp(value.toLowerCase(), "g");
      if (v.name.toLocaleLowerCase().match(reg)) {
        return true;
      }

      return false;
    });

    setCompanyList(searchCompany);
  };
  const optionCompany = () =>
    (companyList || []).map((d) => (
      <Option key={d.id} value={d.id}>
        {`${d.name}, ${d.city_name}`}
      </Option>
    ));

  const onSelectStockOpname = (value: number, option: any) => {
    setStockOpnameInfo(
      `${option?.info} Barang, Tanggal ${option?.date}, ${option.isFinish}`
    );
    setSelectedStockOpname(value);
  };

  const onSearchStockOpname = (value: string) => {
    const searchStockOpname = stockOpnameCollection.filter((v, i) => {
      const reg = new RegExp(value.toLowerCase(), "g");
      if (String(v.checked_sum).toLowerCase().match(reg)) {
        return true;
      }

      return false;
    });

    setStockOpnameList(searchStockOpname);
  };

  const optionStockOpname = () =>
    (stockOpanmeList || []).map((d) => (
      <Option
        key={d.id}
        value={d.id}
        date={renderDate(d.end_at)}
        info={d.checked_sum}
        isFinish={
          d.is_finished === true ? "Sudah disesuaikan" : "Belum disesuaikan"
        }
      >
        {`${d.checked_sum}/${d.total_product} produk, Tgl ${renderDate(
          d.end_at
        )}, ${d.is_finished === true ? "Sudah disesuaikan" : "Belum disesuaikan"
          }`}
      </Option>
    ));

  const onSelectCompanyUser = (value: number) => {
    setSelectedCompanyUser(value);
  };

  const onSearchCompanyUser = (value: string) => {
    const searchCompanyUser = companyUserCollection.filter((v, i) => {
      const reg = new RegExp(value.toLowerCase(), "g");
      if (
        String(v.name || v.username)
          .toLowerCase()
          .match(reg)
      ) {
        return true;
      }

      return false;
    });

    setCompanyUserList(searchCompanyUser);
  };

  const optionCompanyUser = () => {
    return (companyUserList || []).map((d) => (
      <Option key={d.id} value={d.id}>
        {d.name || d.username}
      </Option>
    ));
  };

  const renderDate = (
    date: string | null | undefined,
    includeHour?: boolean
  ) => {
    const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
    return date ? moment(date).format(format) : "-";
  };

  const getCompany = async () => {
    const response = await getPharmacyList();
    setCompanyCollection(response || []);
    setCompanyList(response);
  };

  useEffect(() => {
    getCompany();
  }, []);

  const getStockOpname = async () => {
    try {
      const response = await getListData(
        "stock_opname_history",
        selectedCompany
      );
      setStockOpnameCollection(response);
      setStockOpnameList(response);
    } catch (err) { }
  };

  const getUserCompany = async () => {
    const response = await getCompanyUser(selectedCompany);
    (response || []).unshift({
      id: 0,
      name: "Semua Pegawai",
      email: "",
      is_matamata: 0,
      status: "employee",
      username: "",
    });
    setCompanyUserCollection(response);
    setCompanyUserList(response);
    setSelectedCompanyUser(0);
  };

  useEffect(() => {
    getStockOpname();
    getUserCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  const onDateChange = debounce((values: any, dateString: [string, string]) => {
    setDate({
      date_start: values[0].format("YYYY-MM-DD"),
      date_end: values[1].format("YYYY-MM-DD"),
    });
  }, 800);

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Sider breakpoint="lg" collapsedWidth="0">
          <Sidebar activeTab={"export-data"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>List ExportData</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Row style={{ marginBottom: "16px" }}>
              <h2>Export Stok</h2>
            </Row>
            <Row style={{ marginBottom: "16px" }}>
              <Col md={6}>
                <Select
                  data-testid="exp-stock-select-company"
                  style={{ width: "100%" }}
                  showSearch
                  showArrow={false}
                  filterOption={false}
                  onChange={onSelectCompanyChange}
                  onSearch={onSearchCompany}
                  notFoundContent={null}
                >
                  {optionCompany()}
                </Select>
              </Col>
              <Col md={6}>
                <Button
                  data-testid="exp-stock-button-action"
                  onClick={() => handleExportData("stock")}
                  disabled={isLoading}
                >
                  Export
                </Button>
              </Col>
            </Row>

            <Row style={{ marginBottom: "16px" }}>
              <h2>Export Sales History</h2>
            </Row>
            <Row style={{ marginBottom: "16px" }}>
              <Col md={6}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  data-testid="exp-sales-select-company"
                  showArrow={false}
                  filterOption={false}
                  onChange={onSelectCompanyChange}
                  onSearch={onSearchCompany}
                  notFoundContent={null}
                >
                  {optionCompany()}
                </Select>
              </Col>
              <Col md={6}>
                <RangePicker
                  defaultValue={[moment(), moment()]}
                  style={{ width: "100%" }}
                  data-testid="exp-sales-date-range"
                  onChange={onDateChange}
                />
              </Col>
              <Col md={6}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  showArrow={false}
                  filterOption={false}
                  onChange={onSelectCompanyUser}
                  data-testid="exp-sales-select-user"
                  onSearch={onSearchCompanyUser}
                  notFoundContent={null}
                  value={selectedCompanyUser}
                >
                  {optionCompanyUser()}
                </Select>
              </Col>
              <Col md={6}>
                <Button
                  data-testid="exp-sales-button-action"
                  onClick={() => handleExportData("sales_history")}
                  disabled={isLoading}
                >
                  Export
                </Button>
              </Col>
            </Row>

            <Row style={{ marginBottom: "16px" }}>
              <h2>Export Stock Opname</h2>
            </Row>
            <Row style={{ marginBottom: "16px" }}>
              <Col md={6}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  data-testid="exp-stock-opname-select-company"
                  showArrow={false}
                  filterOption={false}
                  onChange={onSelectCompanyChange}
                  onSearch={onSearchCompany}
                  notFoundContent={null}
                >
                  {optionCompany()}
                </Select>
              </Col>
              <Col md={12}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  showArrow={false}
                  filterOption={false}
                  data-testid="exp-stock-opname-select-data"
                  onChange={onSelectStockOpname}
                  onSearch={onSearchStockOpname}
                  notFoundContent={null}
                >
                  {optionStockOpname()}
                </Select>
              </Col>
              <Col md={6}>
                <Button
                  data-testid="exp-stock-opname-button-action"
                  onClick={() => handleExportData("stock_opname_report")}
                  disabled={isLoading}
                >
                  Export
                </Button>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default ExportDataComponent;
