import { notification } from "antd";

import axios from '../../../config/axios'

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const getLoggerDetail = async (id: number) => {
  try {
    const url = `${BASE_URL_BETA}/logger`;
    const response = await axios.get(url, { params: {logger_id : id}});

    if (response && response.status === 200) {
        return response.data.log
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
    throw new Error(err);
  }
};

export default getLoggerDetail
