import { notification } from "antd";
import axios from "../../../../config/axios";
import { BASE_URL_BETA } from "../../../../constant/api";
import { AddOnPayload } from "./interface";

export const postAddOnChanges = async (
  pharmacyId: number | string,
  payload: AddOnPayload
) => {
  try {
    const url = `${BASE_URL_BETA}/admin/company/${pharmacyId}/addon`;
    await axios.post(url, payload);

    notification.success({ message: "Berhasil menambahkan add-on" });

    return [];
  } catch (err) {
    notification.error({ message: "Gagal menambahkan add-on" });
    return [];
  }
};

export const putAddOnChanges = async (
  pharmacyId: number | string,
  addOnId: number,
  payload: AddOnPayload
) => {
  try {
    const url = `${BASE_URL_BETA}/admin/company/${pharmacyId}/addon/${addOnId}`;
    await axios.put(url, payload);

    notification.success({ message: "Berhasil memperbaharui add-on" });

    return [];
  } catch (err) {
    notification.error({ message: "Gagal memperbaharui add-on" });
    return [];
  }
};
