export interface Product {
  id: number;
  name: string;
  price: number;
  unit: number;
  price_per_unit: number;
}

export interface PriceHistory {
  id: number;
  created_at: string;
  product_id: number;
  product_name: string;
  price_per_unit: number;
  price_per_unit_before: number;
  stock_balance: number;  
  description: string;
  action_type: string | null;
  source_id: number;
  source_number: string;
  qty_balance: number;
  details: PriceHistoryDetail[];
}

export interface PriceHistoryDetail {
  id: number;
  batch_no: string | null;
  batch_cogs: number;
  qty_change: number;
  price_buy: number;
  vat_amount: number;
  vat_percentage_invoice: number;
  discount_type_product: string;
  discount_amount_product: number;
  discount_type_invoice: string;
  discount_amount_invoice: number;
  description: string;
}

export const defaultProduct: Product = {
  id: 0,
  name: "",
  price: 0,
  unit: 0,
  price_per_unit: 0,
};

export const defaultPriceHistoryLog: PriceHistoryDetail = {
  id: 0,
  batch_no: "",
  batch_cogs: 0,
  qty_change: 0,
  price_buy: 0,
  vat_amount: 0,
  vat_percentage_invoice: 0,
  discount_type_product: "",
  discount_amount_product: 0,
  discount_type_invoice: "",
  discount_amount_invoice: 0,
  description: ""
};

export const defaultPriceHistory: PriceHistory = {
  id: 0,
  created_at: "",
  product_id: 0,
  product_name: "",
  price_per_unit: 0,
  price_per_unit_before: 0,
  stock_balance: 0,
  description: "",
  action_type: "211",
  source_id: 0,
  source_number: "",
  qty_balance: 0,
  details: [defaultPriceHistoryLog]
};
