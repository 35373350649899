import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const putActiveDate = async (
   companyID: number,
   activeUntil: string,
   status: string,
   isPaid: number,
   isInternal: number,
   hiddenWorkspace: boolean,
   reason: string,
   title: string
) => {
   try {
      const url = `${BASE_URL_BETA}/admin/company/${companyID}`;

      const payload = {
         active_until: activeUntil,
         status: status,
         is_paid: isPaid,
         is_internal: isInternal,
         is_hidden: hiddenWorkspace,
         reason: reason,
         title,
      };

      const response = await axios.put(url, payload);

      if (response && response.status === 200) {
         return response.data.company;
      }
   } catch (err) {
      notification.error({
         message: "Gagal memperbaharui",
         placement: "topRight",
         duration: 2,
      });
      throw new Error();
   }
};

export default putActiveDate;
