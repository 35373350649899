export const RESPONSE_CODE = [
    {code: '', description: 'Show All'},
    {code: 100, description: 'Continue'},
    {code: 101, description: 'Switching Protocols'},
    {code: 102, description: 'Processing'}, // WebDAV; RFC 2518
    {code: 103, description: 'Early Hints'}, // RFC 8297
    {code: 200, description: 'OK'},
    {code: 201, description: 'Created'},
    {code: 202, description: 'Accepted'},
    {code: 203, description: 'Non-Authoritative Information'}, // since HTTP/1.1
    {code: 204, description: 'No Content'},
    {code: 205, description: 'Reset Content'},
    {code: 206, description: 'Partial Content'}, // RFC 7233
    {code: 207, description: 'Multi-Status'}, // WebDAV; RFC 4918
    {code: 208, description: 'Already Reported'}, // WebDAV; RFC 5842
    {code: 226, description: 'IM Used'}, // RFC 3229
    {code: 300, description: 'Multiple Choices'},
    {code: 301, description: 'Moved Permanently'},
    {code: 302, description: 'Found'}, // Previously "Moved temporarily"
    {code: 303, description: 'See Other'}, // since HTTP/1.1
    {code: 304, description: 'Not Modified'}, // RFC 7232
    {code: 305, description: 'Use Proxy'}, // since HTTP/1.1
    {code: 306, description: 'Switch Proxy'},
    {code: 307, description: 'Temporary Redirect'}, // since HTTP/1.1
    {code: 308, description: 'Permanent Redirect'}, // RFC 7538
    {code: 400, description: 'Bad Request'},
    {code: 401, description: 'Unauthorized'}, // RFC 7235
    {code: 402, description: 'Payment Required'},
    {code: 403, description: 'Forbidden'},
    {code: 404, description: 'Not Found'},
    {code: 405, description: 'Method Not Allowed'},
    {code: 406, description: 'Not Acceptable'},
    {code: 407, description: 'Proxy Authentication Required'}, // RFC 7235
    {code: 408, description: 'Request Timeout'},
    {code: 409, description: 'Conflict'},
    {code: 410, description: 'Gone'},
    {code: 411, description: 'Length Required'},
    {code: 412, description: 'Precondition Failed'}, // RFC 7232
    {code: 413, description: 'Payload Too Large'}, // RFC 7231
    {code: 414, description: 'URI Too Long'}, // RFC 7231
    {code: 415, description: 'Unsupported Media Type'}, // RFC 7231
    {code: 416, description: 'Range Not Satisfiable'}, // RFC 7233
    {code: 417, description: 'Expectation Failed'},
    {code: 418, description: 'I\'m a teapot'}, // RFC 2324, RFC 7168
    {code: 421, description: 'Misdirected Request'}, // RFC 7540
    {code: 422, description: 'Unprocessable Entity'}, // WebDAV; RFC 4918
    {code: 423, description: 'Locked'}, // WebDAV; RFC 4918
    {code: 424, description: 'Failed Dependency'}, // WebDAV; RFC 4918
    {code: 425, description: 'Too Early'}, // RFC 8470
    {code: 426, description: 'Upgrade Required'},
    {code: 428, description: 'Precondition Required'}, // RFC 6585
    {code: 429, description: 'Too Many Requests'}, // RFC 6585
    {code: 431, description: 'Request Header Fields Too Large'}, // RFC 6585
    {code: 451, description: 'Unavailable For Legal Reasons'}, // RFC 7725
    {code: 500, description: 'Internal Server Error'},
    {code: 501, description: 'Not Implemented'},
    {code: 502, description: 'Bad Gateway'},
    {code: 503, description: 'Service Unavailable'},
    {code: 504, description: 'Gateway Timeout'},
    {code: 505, description: 'HTTP Version Not Supported'},
    {code: 506, description: 'Variant Also Negotiates'}, // RFC 2295
    {code: 507, description: 'Insufficient Storage'}, // WebDAV; RFC 4918
    {code: 508, description: 'Loop Detected'}, // WebDAV; RFC 5842
    {code: 510, description: 'Not Extended'}, // RFC 2774
    {code: 511, description: 'Network Authentication Required'}, // RFC 6585
];