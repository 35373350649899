import { notification } from "antd";

import axios from '../../../config/axios'

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const getUserList = async () => {
  try {
    const url = `${BASE_URL_BETA}/admin/user`;
    const response = await axios.get(url);

    if (response && response.status === 200) {
        return response.data.collection
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
    throw new Error(err);
  }
};

export default getUserList
