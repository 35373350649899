import React from "react";
import { Row, Col, Collapse, Table } from "antd";
import moment from "moment";

// type
import { DataAnomaly, DataAnomalyDetail } from "../../types/dataAnomaly";
type Props = {
  selectedAnomaly: DataAnomaly;
  AnomalyDetail: DataAnomalyDetail[];
};

const AnomalyDetail = (props: Props) => {
  const { selectedAnomaly, AnomalyDetail } = props;

  const renderDate = (
    date: string | null | undefined,
    includeHour?: boolean
  ) => {
    const format = includeHour ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY";
    return date ? moment(date).format(format) : "-";
  };

  const detailColumns = [
    {
      title: "ID Apotek",
      dataIndex: "pharmacy_id",
      key: "pharmacy_id",
    },
    {
      title: "Nama Apotek",
      dataIndex: "pharmacy_name",
      key: "pharmacy_name",
    },
    {
      title: "ID Barang",
      dataIndex: "product_id",
      key: "product_id",
    },
    {
      title: "Nama Barang",
      dataIndex: "product_name",
      key: "product_name",
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <h2>Anomaly Detail</h2>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          DateTime: {renderDate(selectedAnomaly?.created_at, true)}
        </Col>
        <Col span={6}>Tipe: {selectedAnomaly?.data_type}</Col>
        <Col span={6}>
          Tipe: {selectedAnomaly?.status ? "Passed" : "Not Passed"}
        </Col>
      </Row>
      <Row>
        <Col span={24}>Label: {selectedAnomaly?.label}</Col>
      </Row>
      <Row>
        <Col span={24}>Query: {selectedAnomaly?.query}</Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table dataSource={AnomalyDetail} columns={detailColumns} />;
        </Col>
      </Row>
    </>
  );
};

export default AnomalyDetail;
