import { Button, Col, Input, Row, Select, Table } from "antd";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import { useMemo } from "react";
import Sidebar from "../../../component/Sidebar";
import { Product } from "../../../types/priceHistory";
import { useTaxReportHistory } from "./hooks/useTaxReportHistory";

export const TaxReportHistory = () => {
   const {
      filteredPharmacies,
      productList,
      filter,
      isFetching,
      fetchPharmacies,
      onChangeProductNameFilter,
      searchName,
      totalData,
      setSearchName,
      onNavProductDetail,
      onChangePharmacy,
      onChangeSearchPharmacyFilter,
   } = useTaxReportHistory();

   const { Option } = Select;

   const renderList = useMemo(() => {
      return filteredPharmacies.map((d) => (
         <Option key={d.id} value={d.id.toString()}>
            {d.name}
         </Option>
      ));
   }, [filteredPharmacies]);

   const getFormattedList = useMemo(() => {
      return productList.map((p: Product) => {
         return {
            id: p.id.toString(),
            name: p.name,
            unit: p.unit,
         };
      });
   }, [productList]);

   const columns = [
      {
         title: "Nama",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Stok",
         dataIndex: "unit",
         key: "unit",
      },
   ];

   return (
      <Layout style={{ height: "100vh" }}>
         <Sider breakpoint='lg' collapsedWidth='0'>
            <Sidebar activeTab={"tax-report-history"} />
         </Sider>
         <Layout>
            <Header style={{ background: "#fff" }}>
               <h1>Tax Report History</h1>
            </Header>
            <Content
               style={{
                  padding: "24px",
                  paddingBottom: "100px",
                  overflowY: "scroll",
               }}
            >
               <Row style={{ marginBottom: "16px" }} gutter={16}>
                  <Col span={8}>
                     <label style={{ fontWeight: 600, lineHeight: "26px" }}>Workspace :</label>
                     <Select
                        loading={fetchPharmacies}
                        style={{ width: "100%" }}
                        showSearch
                        showArrow={true}
                        filterOption={false}
                        value={filter.company_id ? filter.company_id.toString() : ""}
                        onChange={onChangePharmacy}
                        onSearch={onChangeSearchPharmacyFilter}
                        notFoundContent={null}
                        placeholder='Pilih Apotek'
                     >
                        {renderList}
                     </Select>
                  </Col>
               </Row>

               {filter.company_id ? (
                  <form
                     onSubmit={(e) => {
                        e.preventDefault();
                        onChangeProductNameFilter();
                     }}
                  >
                     <Row>
                        <Col span={8}>
                           <Row>
                              <Col span={18}>
                                 <Input
                                    value={searchName}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setSearchName(e.target.value)}
                                    placeholder='Nama barang'
                                 />{" "}
                              </Col>
                              <Col style={{ marginLeft: "4px" }}>
                                 <Button type='primary'>Cari</Button>
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                  </form>
               ) : null}

               <Table
                  style={{ marginTop: "20px" }}
                  rowKey={(record) => record.id}
                  dataSource={getFormattedList}
                  columns={columns}
                  pagination={{
                     current: filter.current,
                     pageSize: filter.pageSize,
                     total: totalData,
                  }}
                  loading={isFetching}
                  onRow={(record, rowIndex) => {
                     return {
                        onClick: (event) => {
                           onNavProductDetail(record.id.toString());
                        }, // click row
                     };
                  }}
               />
            </Content>
         </Layout>
      </Layout>
   );
};
