import { notification } from "antd";
import moment from "moment";
import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";
type queryParamsType = {
  exportType: "stock" | "sales_history" | "stock_opname_report";
  companyID: number;
  companyName?: string;
  dateStart?: string;
  dateEnd?: string;
  stockOpnameID?: number;
  userID?: number;
  stockOpnameInfo?: string;
};
const getExportData = async (params: queryParamsType) => {
  const {
    exportType,
    companyID,
    dateStart = "",
    dateEnd = "",
    stockOpnameID = 0,
    userID = 0,
  } = params;
  try {
    let queryParams = new URLSearchParams({
      export_type: exportType,
      company_id: companyID,
      date_start: dateStart,
      date_end: dateEnd,
      stock_opname_id: stockOpnameID,
      user_id: userID,
    } as any).toString();

    const url = `${BASE_URL_BETA}/admin/export-data?${queryParams}`;

    const response = await axios.get(url).then((response) => {
      if (response.status == 202) {
        notification.success({
          message: response.data.message,
          placement: "topRight",
          duration: 3,
        });
      } else {
        const type = response.headers["content-type"];
        var link = document.createElement("a");
        link.download = renderFileName(params);
        link.href = `data:${type};base64,` + response.data;
        link.click();
        notification.success({
          message: "Data berhasil diunduh",
          placement: "topRight",
          duration: 3,
        });
      }
    });
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
  }
};

const renderFileName = (fileData: queryParamsType) => {
  switch (fileData.exportType) {
    case "stock":
      return `${fileData.companyName} Laporan Stok.xlsx`;
    case "sales_history":
      const dateStartWithYear = moment(fileData.dateStart).format("YYYY MMM D");
      const dateStartMonth = moment(fileData.dateStart).format("MMM");
      const dateStartYear = moment(fileData.dateStart).format("YYYY");

      const dateEndWithYear = moment(fileData.dateEnd).format("YYYY MM D");
      const dateEndYear = moment(fileData.dateEnd).format("YYYY");
      const dateEndMonth = moment(fileData.dateEnd).format("MMM");
      const dateEndDate = moment(fileData.dateEnd).format("D");

      var period;

      if (dateStartYear == dateEndYear && dateStartMonth == dateEndMonth) {
        period = `${dateStartWithYear} - ${dateEndDate}`;
      } else if (
        dateStartYear == dateEndYear &&
        dateStartMonth != dateEndMonth
      ) {
        period = `${dateStartWithYear} - ${dateEndMonth} ${dateEndDate}`;
      } else {
        period = `${dateStartWithYear} - ${dateEndWithYear}`;
      }

      const fileName = `${fileData.companyName} - Laporan penjualan - ${period}`;

      return `${fileName}.xlsx`;
    case "stock_opname_report":
      return `${fileData.companyName} Laporan Stok Opname ${fileData.stockOpnameInfo}.xlsx`;
  }
};

export default getExportData;
