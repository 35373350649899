import axios from "../../../config/axios";
import { notification } from "antd";
import { BASE_URL_BETA } from "../../../constant/api";

export const getDataFeatureFlag = async (data: { endPoint: string }) => {
  try {
    const url = `${BASE_URL_BETA}${data.endPoint}`;
    const response = await axios.get(url, {
      params: {
        origin: "clima",
      },
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
  }
};

export const postDataFeatureFlag = async (data: { endPoint: string; data: any }) => {
  const url = `${BASE_URL_BETA}${data.endPoint}`;
  try {
    const response = await axios.post(url, data.data);
    if (response && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    notification.error({
      message: "Gagal mengirim",
      placement: "topRight",
      duration: 2,
    });
  }
};

export const putDataFeatureFlag = async (data: { endPoint: string; data: any }) => {
  const url = `${BASE_URL_BETA}${data.endPoint}`;
  try {
    const response = await axios.put(url, data.data);
    if (response && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    notification.error({
      message: "Gagal mengubah",
      placement: "topRight",
      duration: 2,
    });
  }
};

export const deleteDataFeatureFlag = async (data: { endPoint: string }) => {
  const url = `${BASE_URL_BETA}${data.endPoint}`;
  try {
    const response = await axios.delete(url);
    if (response && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
  }
};

export const getPharmacyGroupListAPI = async () => {
  try {
    const url = `${BASE_URL_BETA}/system/feature/company`;
    const response = await axios.get(url, {
      params: {
        origin: "clima",
      },
    });

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notification.error({
      message: "Gagal memuat",
      placement: "topRight",
      duration: 2,
    });
  }
};
