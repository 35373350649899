import { QueryCache, QueryClient } from "react-query";

export const queryCache = new QueryCache({
   onError: (error) => {
      console.log(error);
   },
   onSuccess: (data) => {
      console.log(data);
   },
});

export const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         refetchOnWindowFocus: false,
      },
   },
});
