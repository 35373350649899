import { notification } from "antd";

import axios from "../../../config/axios";

// constant
import { BASE_URL_BETA } from "../../../constant/api";

const putUserRole = async (email: string, role: string = "client") => {
  try {
    const url = `${BASE_URL_BETA}/admin/user`;

    const payload = {
      email: email,
      role: role,
    };

    const response = await axios.post(url, payload);

    if (response && response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notification.error({
      message: "Gagal memperbaharui",
      placement: "topRight",
      duration: 2,
    });
    throw new Error(err);
  }
};

export default putUserRole;
